import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { isEmpty } from 'lodash-es';

/**
 * Component for displaying status messages.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const Message = ({ inModal = false }) => {
  const { data, type, showInModal } = useSelector((state) => state.lastMessage);
  const { message, error } = data;

  if ((isEmpty(message) && isEmpty(error)) || (!inModal && showInModal)) {
    return null;
  }

  return (
    <Box mt={2}>
      {error ? (
        <Alert severity={type}>
          {message && <AlertTitle>{message}</AlertTitle>}
          {Object.keys(error).map((key) =>
            error[key] ? (
              <div
                key={key}
                dangerouslySetInnerHTML={{
                  __html: error[key],
                }}
              />
            ) : null,
          )}
        </Alert>
      ) : (
        <Alert severity={type}>{message}</Alert>
      )}
    </Box>
  );
};

export default Message;
