const convertCmToFtIn = (value) => {
  let inches = value * 0.39;
  let feet = Math.floor(inches / 12);
  const remaining_inches = inches / 12 - feet;
  inches = Math.round(remaining_inches * 12);
  if (inches === 12) {
    feet++;
    inches = 0;
  }
  return `${feet}' ${inches}"`;
};

const convertCmToIn = (value) => Math.round(value * 0.39);
const convertKgToLb = (value) => Math.round(value * 2.2);

export const formatCmToIn = (value) => `${value} cm | ${convertCmToIn(value)}"`;
export const formatKgToLb = (value) =>
  `${value} kg | ${convertKgToLb(value)} lbs.`;
export const formatCmToFtIn = (value) => `${value} cm | ${convertCmToFtIn(value)}`;
