import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ResultsList from 'components/ResultsList';
import { useHistory } from 'react-router';
import HealthTipsSignupForm from 'forms/HealthTipsSignupForm';
import SharingButtons from 'components/SharingButtons';

const useStyles = makeStyles((theme) => ({
  hrTop: theme.hrTop,
}));

function ProfileView() {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Typography variant="h1">{t('Your Profile')}</Typography>
      <Grid container spacing={4} style={{ marginTop: 12 }}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              history.push('/profile-edit');
            }}
          >
            {t('Update Your Profile')}
          </Button>
        </Grid>
      </Grid>
      <ResultsList />
      <Box className={classes.hrTop}>
        <HealthTipsSignupForm />
      </Box>
      <SharingButtons />
    </>
  );
}

export default ProfileView;
