import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MainMenu from 'components/MainMenu';
import LanguageSwitcher from 'components/LanguageSwitcher';

import LogoSVG from 'images/MHC-full-positive.svg';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  toolbar: {
    flexWrap: 'nowrap',
    width: '100%',
    maxWidth: 800,
    minHeight: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1, 2),
    margin: 0,
    [theme.breakpoints.up(800)]: {
      padding: theme.spacing(3),
    },
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: 200,
    [theme.breakpoints.up(800)]: {
      width: 300,
    },
  },
}));

function Header() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <AppBar position="absolute" color="default" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Link className={classes.title} to="/" color="inherit">
          <img src={`${process.env.IMAGE_ROOT}${LogoSVG}`} alt={t("Men's Health Check")} className={classes.logo} />
        </Link>
        <LanguageSwitcher />
        <MainMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
