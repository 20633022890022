import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';

import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { setDraft } from 'redux/reducers/User.es6';

const selectNext = (nextStep, getState) => {
  if (typeof nextStep === 'string') {
    return nextStep;
  }

  if (typeof nextStep === 'function') {
    return nextStep({ values: getState().values });
  }

  const selectedValue = get(getState().values, nextStep.when);

  return nextStep.stepMapper[selectedValue];
};

const NextButton = ({
  nextStep,
  wizardState,
  valid,
  handleNext,
  nextLabel,
  getState,
  handleSubmit,
  submitLabel,
  dispatch,
}) => (
  <Button
    variant="contained"
    color="secondary"
    disabled={!valid || getState().validating || getState().submitting}
    onClick={() => {
      dispatch(
        setDraft({
          initialState: wizardState,
          values: getState().values,
        }),
      );
      // Scroll to top on each step.
      // @todo: could be done smarter.
      // setTimeout() seems to be required on desktop browsers, otherwise they
      // do not scroll.
      setTimeout(function () {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 2);
      return nextStep
        ? handleNext(selectNext(nextStep, getState))
        : handleSubmit();
    }}
  >
    {nextStep ? nextLabel : submitLabel}
  </Button>
);

NextButton.propTypes = {
  nextStep: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  wizardState: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.node.isRequired,
  valid: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  nextLabel: PropTypes.node.isRequired,
  getState: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  wizardBody: {
    padding: 24,
    margin: 0,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: 16,
  },
  buttonsContainer: {
    marginTop: 36,
  },
}));

const WizardStepButtons = ({ buttons: Buttons, ...props }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  if (Buttons) {
    return <Buttons classes={classes} {...props} />;
  }

  const {
    wizardState,
    disableBack,
    handlePrev,
    nextStep,
    handleNext,
    buttonLabels: { cancel, submit, back, next },
    formOptions,
    ButtonContainerProps,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justify="space-evenly"
      {...ButtonContainerProps}
      className={`${classes.buttonsContainer} ${ButtonContainerProps.className}`}
    >
      <FormSpy
        subscription={{ valid: true, validating: true, submitting: true }}
      >
        {() => (
          <>
            <Grid item md={12} xs={12} className={classes.buttons}>
              <Button
                disabled={disableBack}
                onClick={handlePrev}
                className={classes.button}
              >
                {back}
              </Button>
              <NextButton
                {...formOptions}
                handleNext={handleNext}
                wizardState={wizardState}
                nextStep={nextStep}
                nextLabel={next}
                submitLabel={submit}
                dispatch={dispatch}
              />
            </Grid>
          </>
        )}
      </FormSpy>
    </Grid>
  );
};

WizardStepButtons.propTypes = {
  ButtonContainerProps: PropTypes.object,
  disableBack: PropTypes.bool,
  handlePrev: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  nextStep: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      when: PropTypes.string.isRequired,
      stepMapper: PropTypes.object.isRequired,
    }),
    PropTypes.func,
  ]),
  wizardState: PropTypes.object.isRequired,
  buttonLabels: PropTypes.shape({
    submit: PropTypes.node.isRequired,
    cancel: PropTypes.node.isRequired,
    back: PropTypes.node.isRequired,
    next: PropTypes.node.isRequired,
  }).isRequired,
  buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  formOptions: PropTypes.shape({
    getState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }),
};

WizardStepButtons.defaultProps = {
  ButtonContainerProps: {},
};

export default WizardStepButtons;
