import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import { setLastMessage } from 'redux/reducers/Message.es6';
import { loginSuccess } from 'redux/reducers/User.es6';

/**
 * Runs Redux dispatch action to create profile.
 *
 * @param {function} dispatch
 * @param {Object} history
 * @param {function} t
 * @param {boolean} inModal
 * @param {function} onClose
 *
 * @return {Promise}
 *
 * @constructor inherit
 */
export default ({ dispatch, history, t, inModal = false, onClose = null }) => (
  data,
) =>
  drupalApi
    .post('/user/register?_format=json', {
      name: { value: `email_registration_${data.mail}` },
      mail: { value: data.mail },
      pass: { value: data.pass },
      field_accept_electronic_comms: { value: data.accept_electronic_comms },
    })
    .then((response) => {
      // Login user after registration.
      drupalApi
        .post('/user/login?_format=json', {
          name: response.data.name[0].value,
          pass: data.pass,
        })
        .then((response_login) => {
          batch(() => {
            dispatch(loginSuccess(response_login.data));
            dispatch(
              setLastMessage({
                data: {
                  message: t('Registration successful. You are now logged in.'),
                },
                type: 'success',
              }),
            );
          });
          if (inModal) {
            onClose();
          } else {
            history.push('/');
          }
        })
        .catch((error) =>
          dispatch(
            setLastMessage({
              data: error.response.data,
              type: 'error',
              showInModal: inModal,
            }),
          ),
        );
    })
    .catch((error) =>
      dispatch(
        setLastMessage({
          data: error.response.data,
          type: 'error',
          showInModal: inModal,
        }),
      ),
    );
