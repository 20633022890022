import React from 'react';

import RegisterForm from 'forms/RegisterForm';

/**
 * Generates "RegisterView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function RegisterView() {
  return (
    <>
      <RegisterForm />
    </>
  );
}

export default RegisterView;
