import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import userLogoutAction from 'redux/actions/UserLogoutAction.es6';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button, Box, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

import SignInSVG from 'images/SignIn.svg';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  signin: {
    width: 34,
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      width: 50,
    },
  },
}));

/**
 * Component for switching Languages.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const MainMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      {!isAuthenticated && (
        <Box>
          <IconButton
            color="secondary"
            onClick={() => history.push('/login')}
            className={classes.menuButton}
            aria-label={t('Sign In')}
          >
            <img
              src={`${process.env.IMAGE_ROOT}${SignInSVG}`}
              alt={t('Sign In')}
              className={classes.signin}
            />
          </IconButton>
        </Box>
      )}
      {isAuthenticated && (
        <div>
          <Button
            aria-controls="user-menu"
            aria-haspopup="true"
            aria-label={t('Menu')}
            className={classes.menuButton}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <AccountCircle />
          </Button>
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                history.push('/profile');
                setAnchorEl(null);
              }}
            >
              {t('My Profile')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push('/profile-edit');
                setAnchorEl(null);
              }}
            >
              {t('Edit Profile')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                dispatch(userLogoutAction(history, '/'));
              }}
            >
              {t('Logout')}
            </MenuItem>
          </Menu>
        </div>
      )}{' '}
    </>
  );
};

export default MainMenu;
