import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItem, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  resultRow: {
    maxWidth: 400,
  },
}));
/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const Result = ({ uuid, completed_date, link_text }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <ListItem key={`${uuid}`} className={classes.resultRow}>
        <Grid container direction="row" wrap="nowrap" alignItems="center">
          <Grid item xs={6}>
            {completed_date}
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              onClick={() => history.push(`/survey/result/${uuid}`)}
              key={`${uuid}-link`}
            >
              {t('See Results')}
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default Result;
