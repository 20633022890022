import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisclaimerPage from 'pages/DisclaimerPage';
import { useHistory } from 'react-router';
import { clearDraft } from 'redux/reducers/User.es6';

const useStyles = makeStyles((theme) => ({
  container: {
    '& button': {
      minHeight: '76px',
    },
  },
}));

function DisclaimerView() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { draft, isAuthenticated, lastResult } = useSelector(
    (state) => state.user,
  );

  let buttons = [
    <Grid key="button-start" item xs={12} sm={4}>
      <Button
        onClick={() => {
          history.push('/survey');
        }}
        variant="contained"
        color="secondary"
        size="large"
        fullWidth
      >
        {t('Start Survey')}
      </Button>
    </Grid>,
  ];

  if (draft && draft.initialState) {
    buttons = [
      <Grid key="button-continue" item xs={12} sm={4}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => {
            history.push('/survey');
          }}
        >
          {t('Continue Survey')}
        </Button>
      </Grid>,
      <Grid key="button-restart" item xs={12} sm={4}>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          onClick={() => {
            dispatch(clearDraft());
            history.push('/survey');
          }}
        >
          {t('Restart Survey')}
        </Button>
      </Grid>,
    ];
  }

  if (lastResult) {
    buttons.push(
      <Grid key="button-lastresult" item xs={12} sm={4}>
        <Button
          onClick={() => {
            history.push(`/survey/result/${lastResult}`);
          }}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          {t('View Last Result')}
        </Button>
      </Grid>,
    );
  }

  return (
    <>
      <Grid container spacing={4} className={classes.container}>
        {buttons}
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DisclaimerPage />
        </Grid>
      </Grid>
    </>
  );
}

export default DisclaimerView;
