/**
 * @file Constance redux reducer.
 */
import { createSlice } from '@reduxjs/toolkit';

export const webformSlice = createSlice({
  name: 'webform',
  initialState: {
    metaData: {},
    isLoading: {},
    submitError: {},
  },
  reducers: {
    setMetaData: (state, action) => {
      state.metaData[action.payload.id] = action.payload;
    },
    setMetaDataIsLoading: (state, action) => {
      state.isLoading[action.payload.webformId] = action.payload.value;
    },
  },
});

export const { setMetaData, setMetaDataIsLoading } = webformSlice.actions;

export default webformSlice.reducer;
