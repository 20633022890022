import { merge } from 'lodash-es';
import MuiComponentMapper from '@data-driven-forms/mui-component-mapper/component-mapper';
import Markup from 'forms/components/Markup';
import Subform from 'forms/components/Subform';
import Checkbox from 'forms/components/Checkbox';
import Radio from 'forms/components/Radio';
import TextField from 'forms/components/TextField';
import Select from 'forms/components/Select';
import Slider from 'forms/components/Slider';
import Wizard from 'forms/components/Wizard';

const componentMapper = merge({}, MuiComponentMapper, {
  markup: Markup,
  'sub-form': Subform,
  checkbox: Checkbox,
  radio: Radio,
  'text-field': TextField,
  select: Select,
  slider: Slider,
  wizard: Wizard,
});

export default componentMapper;
