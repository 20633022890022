import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';

/**
 * Request a logout token from Drupal.
 * @return {Promise<any | never>}
 */
export default () =>
  drupalApi
    .get('/session/token/logout')
    .then((response) => response.data.logout_token)
    .catch((error) => logger.error(error));
