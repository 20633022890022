import { combineReducers } from 'redux';
import user from 'redux/reducers/User.es6';
import webform from 'redux/reducers/Webform.es6';
import lastMessage from 'redux/reducers/Message.es6';
import page from 'redux/reducers/Page.es6';
import assessmentResult from 'redux/reducers/AssessmentResult.es6';
import resultsList from 'redux/reducers/ResultsList.es6';

export default combineReducers({
  user,
  webform,
  lastMessage,
  page,
  assessmentResult,
  resultsList,
});
