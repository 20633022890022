import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import { useSelector } from 'react-redux';

import HelperTextIcon from 'components/HelperTextIcon';

const useStyles = makeStyles(() => ({
  grid: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  container: {
    position: 'relative',
  },
  // helperText: {
  //   position: 'absolute',
  //   right: 0,
  // },
}));

const Subform = ({
  fields,
  title,
  description,
  component,
  TitleGridProps,
  TitleProps,
  DescriptionProps,
  DescriptionGridProps,
  ItemsGridProps,
  name,
  helperText,
  ...rest
}) => {
  const { renderForm } = useFormApi();
  const { user } = useSelector((state) => state);
  const classes = useStyles();
  const access =
    !(
      user.isAuthenticated &&
      (name === 'flexbox_profile_option' || name === 'flexbox_profile_option2')
    ) ?? true;

  /**
   * @todo: Custom logic for handling Sign In / Sign Up buttons.
   */
  const [hide, setHide] = useState(false);
  const handleHide = () => {
    setHide(true);
  };

  if (!fields) {
    fields = [];
  }

  const fieldsModified = fields.map((field) => {
    if (
      field.name === 'continue_anonymously' ||
      field.name === 'continue_anonymously2'
    ) {
      return { ...field, handleHide };
    }
    return field;
  });

  return !hide && access ? (
    <Grid
      className={`${classes.grid} section`}
      name={name}
      item
      xs={12}
      {...rest}
    >
      {(title || description) && (
        <Grid
          className={classNames(
            'field',
            {
              hasTitle: title,
            },
            {
              hasDescription: description,
            },
            classes.container,
          )}
          container
          direction="row"
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
          position="relative"
        >
          <Grid item xs={12} container {...TitleGridProps}>
            {title && (
              <Grid className="heading" item xs={12} {...TitleGridProps}>
                <Typography variant="h2" {...TitleProps}>
                  {title}
                </Typography>
              </Grid>
            )}
            {description && (
              <span
                className="description"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
          </Grid>
          {helperText && (
            <Grid item className={classes.helperText}>
              <HelperTextIcon helperText={helperText} />
            </Grid>
          )}
        </Grid>
      )}
      <Grid className="fields" container {...ItemsGridProps}>
        {renderForm(fieldsModified)}
      </Grid>
    </Grid>
  ) : null;
};

Subform.propTypes = {
  fields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.node,
  name: PropTypes.string,
  description: PropTypes.node,
  helperText: PropTypes.string,
  component: PropTypes.any,
  TitleGridProps: PropTypes.object,
  TitleProps: PropTypes.object,
  DescriptionProps: PropTypes.object,
  DescriptionGridProps: PropTypes.object,
  ItemsGridProps: PropTypes.object,
};

export default Subform;
