import React from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { locales } from 'utils/i18n.es6';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
  },
}));

/**
 * Component for switching Languages.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const LanguageSwitcher = () => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();
  const classes = useStyles();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    history.push(location.pathname);
  };

  return (
    <>
      {Object.keys(locales).map((lng) =>
        lng !== i18n.language ? (
          <Button
            key={lng}
            onClick={() => changeLanguage(lng)}
            className={classes.link}
          >
            {locales[lng]}
          </Button>
        ) : null,
      )}
    </>
  );
};

export default LanguageSwitcher;
