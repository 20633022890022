import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash-es/isEmpty';

import SharingButtons from 'components/SharingButtons';
import LinearProgress from '@material-ui/core/LinearProgress';

import fetchPage from 'redux/actions/fetchPage.es6';
import { useTranslation } from 'react-i18next';

import parse, { attributesToProps, domToReact } from 'html-react-parser';

import SuccessOutlined from '@material-ui/lab/internal/svg-icons/SuccessOutlined';
import BgHomeIntroPNG from 'images/MHC-Home/bg-home-intro-2.png';
import WorldFirstPNG from 'images/MHC-Home/world-first.png';
import StepsPNG from 'images/MHC-Home/steps.png';
import HundredPctPNG from 'images/MHC-Home/100pct.png';
import HomeReasonsPNG from 'images/MHC-Home/bg-home-reasons.png';
import PlusPNG from 'images/MHC-Home/plus.png';
import BCLogo300PNGen from 'images/BCID_Supported_H_RGB_pos_300.png';
import BCLogo200PNGen from 'images/BCID_Supported_H_RGB_pos_200.png';
import BCLogo250PNGfr from 'images/CB-AvecLeSoutien-V-RGB-pos_250.png';
import BCLogo167PNGfr from 'images/CB-AvecLeSoutien-V-RGB-pos_167.png';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  main: theme.main,
  bgWhite: theme.bgWhite,
  page: theme.page,
  home: {
    '& button.get-checked .MuiButton-endIcon': {
      opacity: 0.6,
    },
    '& #home-intro': {
      position: 'relative',
      minHeight: 500,
      background: `transparent url(${process.env.IMAGE_ROOT}${BgHomeIntroPNG}) center 25% no-repeat`,
      backgroundSize: 'cover',

      '& .intro-blurb': {
        position: 'absolute',
        top: theme.spacing(3),
        left: theme.spacing(4.375),
        width: '50%',
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: 25,
        fontWeight: '900',
      },

      '& button': {
        position: 'absolute',
        bottom: theme.spacing(3),
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
    '& #home-main': {
      overflow: 'auto',
      position: 'relative',
      padding: theme.spacing(0, 3),
      textAlign: 'center',
      backgroundColor: theme.bgWhite.backgroundColor,
      color: theme.bgWhite.color,

      '& h1': {
        color: theme.palette.primary.dark,
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 20,
      },
      '& h2': {
        color: theme.palette.primary.dark,
      },
      '& h3': {
        color: theme.palette.primary.dark,
      },
      '& h4': {
        color: theme.palette.primary.dark,
      },
      '& h5': {
        color: theme.palette.primary.dark,
      },
      '& h6': {
        color: theme.palette.primary.dark,
      },

      '& .world-first .icon': {
        display: 'block',
        height: 40,
        background: `transparent url(${process.env.IMAGE_ROOT}${WorldFirstPNG}) center top no-repeat`,
        backgroundSize: 'contain',
        marginBottom: theme.spacing(1.25),
      },
      '& .first-step .icon': {
        display: 'block',
        height: 70,
        background: `transparent url(${process.env.IMAGE_ROOT}${StepsPNG}) center top no-repeat`,
        backgroundSize: 'contain',
        marginBottom: theme.spacing(1.25),
      },
      '& .free .icon': {
        display: 'block',
        height: 35,
        background: `transparent url(${process.env.IMAGE_ROOT}${HundredPctPNG}) center top no-repeat`,
        backgroundSize: 'contain',
        marginBottom: theme.spacing(1.25),
      },

      '& button': {
        display: 'flex',
        margin: theme.spacing(3, 'auto'),
      },
    },
    '& #home-partner-en': {
      padding: theme.spacing(1, 2),
      backgroundColor: '#f5f5f5',
      textAlign: 'center',
      color: common.black,

      '& h2': {
        fontSize: 24,
        textTransform: 'none',
      },

      '& #partner-logo': {
        display: 'block',
        margin: '0 auto',
        width: 200,
        height: 95,
        background: `transparent url(${process.env.IMAGE_ROOT}${BCLogo200PNGen}) center center no-repeat`,

        [theme.breakpoints.up(800)]: {
          width: 300,
          height: 142,
          backgroundImage: `url(${process.env.IMAGE_ROOT}${BCLogo300PNGen})`,
        },

        '& .alt': {
          visibility: 'hidden',
        },
      },
    },
    '& #home-partner-fr': {
      padding: theme.spacing(1, 2),
      backgroundColor: '#f5f5f5',
      textAlign: 'center',
      color: common.black,

      '& h2': {
        fontSize: 24,
        textTransform: 'none',
      },

      '& #partner-logo': {
        display: 'block',
        margin: '0 auto',
        width: 167,
        height: 143,
        background: `transparent url(${process.env.IMAGE_ROOT}${BCLogo167PNGfr}) center center no-repeat`,

        [theme.breakpoints.up(800)]: {
          width: 250,
          height: 215,
          backgroundImage: `url(${process.env.IMAGE_ROOT}${BCLogo250PNGfr})`,
        },

        '& .alt': {
          visibility: 'hidden',
        },
      },
    },
    '& #home-reasons': {
      position: 'relative',
      overflow: 'auto',
      padding: theme.spacing(0, '40%', 10, 3),
      background: `transparent url(${process.env.IMAGE_ROOT}${HomeReasonsPNG}) center top no-repeat`,
      backgroundSize: 'cover',
      fontSize: 14,

      '& h2': {
        fontSize: 20,
      },

      '& h3': {
        fontSize: 16,
      },

      '& .plus': {
        display: 'block',
        height: 26,
        background: `transparent url(${process.env.IMAGE_ROOT}${PlusPNG}) center top no-repeat`,
        backgroundPositionX: 90,
        backgroundSize: 'contain',
        marginBottom: theme.spacing(1.25),
        textAlign: 'left',
        textIndent: -99999,
      },

      '& .because, & .you, & .matter': {
        display: 'block',
        fontFamily: theme.typography.h1.fontFamily,
        fontWeight: 'bold',
        lineHeight: 1,
      },
      '& .because': {
        marginLeft: theme.spacing(1.25),
        fontSize: 24,
      },
      '& .you': {
        marginLeft: theme.spacing(7.5),
        fontSize: 48,
      },
      '& .matter': {
        marginLeft: theme.spacing(16.25),
        fontSize: 29,
      },

      '& button': {
        position: 'absolute',
        bottom: theme.spacing(3),
        left: '50%',
        transform: 'translateX(-50%)',
      },
    },
  },
}));

const HomePage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchPage('home'));
  }, [i18n, i18n.language]);

  const { title, body, isLoading } = useSelector((state) => state.page);

  const options = {
    replace: (domNode) => {
      if (!domNode.attribs || !domNode.attribs.class) {
        return;
      }
      const _classes = domNode.attribs.class.split(' ');

      if (_classes.includes('MuiButton-root')) {
        const props = attributesToProps(domNode.attribs);
        const { href } = props;
        delete props.href;
        return (
          <Button
            onClick={() => {
              history.push(href);
            }}
            endIcon={<SuccessOutlined />}
            {...props}
          >
            {domToReact(domNode.children, options)}
          </Button>
        );
      }
    },
  };

  return !isEmpty(title) && !isEmpty(body) && !isLoading ? (
    <>
      <Box className={`${classes.page} ${classes.home}`}>
        {parse(body, options)}
        <SharingButtons />
      </Box>
    </>
  ) : (
    <LinearProgress />
  );
};

export default HomePage;
