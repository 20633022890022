import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';

/**
 * Request a username from Drupal.
 *
 * @param {string} mail
 *
 * @return {Promise<any | never>}
 */
export default (mail) =>
  drupalApi
    .get(`/youcheck_rest/username/${mail}`)
    .then((response) => response.data.username)
    .catch((error) => logger.error(error));
