import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash-es/isEmpty';

import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';

import fetchWebformMetaData from 'redux/actions/fetchWebformMetaData.es6';
import WebformSubmitter from 'redux/actions/submitters/WebformSubmitter.es6';

import componentMapper from 'forms/components/componentMapper.es6';
import FormTemplate from 'forms/components/FormTemplate';

const HealthTipsSignupForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const webform_id = 'health_tips_signup';

  useEffect(() => {
    dispatch(fetchWebformMetaData(webform_id));
  }, [i18n, i18n.language]);

  const { metaData, isLoading } = useSelector((state) => state.webform);

  return !isEmpty(metaData[webform_id]) && !isLoading[webform_id] ? (
    <>
      <FormRenderer
        schema={metaData[webform_id].schema}
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
        onSubmit={WebformSubmitter({
          dispatch,
          history,
          t,
          webform_id: metaData[webform_id].id,
          redirect: window.location.pathname,
          message: t("We're signing you up."),
        })}
      />
    </>
  ) : null;
};

export default HealthTipsSignupForm;
