import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  type: null,
  showInModal: false,
};

const messageSlice = createSlice({
  name: 'lastMessage',
  initialState,
  reducers: {
    clearLastMessage() {
      return initialState;
    },
    setLastMessage(state, action) {
      const { data, type, showInModal } = action.payload;
      state.data = data;
      state.type = type;
      state.showInModal = showInModal;
    },
  },
});

export default messageSlice.reducer;

export const { clearLastMessage, setLastMessage } = messageSlice.actions;
