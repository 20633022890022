import { drupalApi } from 'utils/RestConnector.es6';
import { setLastMessage } from 'redux/reducers/Message.es6';

/**
 * Runs Redux dispatch action to create profile.
 *
 * @param {function} dispatch
 * @param {function} t
 *
 * @return {Promise}
 *
 * @constructor inherit
 */
export default ({ dispatch, t }) => (data) =>
  drupalApi
    .post('/user/lost-password?_format=json', data)
    .then(() => {
      dispatch(
        setLastMessage({
          data: {
            message: t(
              'Temporary password with further instructions has been sent to your email address.',
            ),
          },
          type: 'success',
        }),
      );
    })
    .catch((error) =>
      dispatch(setLastMessage({ data: error.response.data, type: 'error' })),
    );
