import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Box } from '@material-ui/core';
import componentMapper from 'forms/components/componentMapper.es6';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import FormTemplate from 'forms/components/FormTemplate';

import CreateAccountSubmitter from 'redux/actions/submitters/CreateAccountSubmitter.es6';

const schema = (t) => ({
  title: t('Create Account'),
  submitLabel: t('Create account'),
  fields: [
    {
      id: 'text-field-mail',
      component: 'text-field',
      name: 'mail',
      placeholder: '',
      type: 'text',
      isRequired: true,
      validate: [{ type: 'required', message: t('E-mail is required.') }],
      label: t('E-mail'),
    },
    {
      id: 'text-field-pass',
      component: 'text-field',
      name: 'pass',
      label: t('Password'),
      type: 'password',
      isRequired: true,
      validate: [{ type: 'required', message: t('Password is required.') }],
    },
    {
      id: 'text-field-pass2',
      component: 'text-field',
      name: 'pass2',
      label: t('Repeat password'),
      type: 'password',
      isRequired: true,
      validate: [
        { type: 'required', message: t('Repeat password is required.') },
        (value, allValues) =>
          value !== allValues.pass
            ? t('The specified passwords do not match.')
            : undefined,
      ],
    },
    {
      id: 'checkbox-accept_electronic_comms',
      component: 'checkbox',
      label: t('I consent to receive electronic communications'),
      name: 'accept_electronic_comms',
      initialValue: true,
    },
  ],
});

const RegisterForm = ({ inModal = false, onClose = null }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <FormRenderer
        schema={schema(t)}
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
        onSubmit={CreateAccountSubmitter({
          dispatch,
          history,
          t,
          inModal,
          onClose,
        })}
      />
      <Grid container>
        <Grid item>
          <Box>
            {t('If you already have an account, you can ')}
            <Link to="/login" variant="body2">
              {t('Sign in here')}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterForm;
