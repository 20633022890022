import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  resultKey: {},
  category: {
    borderRadius: 8,
    border: `2px solid white`,
    textAlign: 'center',
    height: 31,
    maxWidth: '30%',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 2.25,
  },
  highRisk: {
    backgroundColor: theme.palette.colorRiskHigh,
  },
  mediumRisk: {
    backgroundColor: theme.palette.colorRiskMedium,
    color: common.black,
  },
  lowRisk: {
    backgroundColor: theme.palette.colorRiskLow,
    color: common.black,
  },
}));

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const ResultKey = ({ className }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Box className={`${className} ${classes.resultKey}`}>
        <Typography variant="h2" gutterBottom>
          {t('Result Key')}
        </Typography>
        <Grid container justify="space-between">
          <Grid
            item
            xs={4}
            className={`${classes.category} ${classes.highRisk}`}
          >
            {t('High Risk')}
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.category} ${classes.mediumRisk}`}
          >
            {t('Medium Risk')}
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.category} ${classes.lowRisk}`}
          >
            {t('Low Risk')}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

ResultKey.propTypes = {
  className: PropTypes.string,
};

ResultKey.defaultProps = {
  className: '',
};

export default ResultKey;
