import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash-es/isEmpty';

import LinearProgress from '@material-ui/core/LinearProgress';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';

import fetchWebformMetaData from 'redux/actions/fetchWebformMetaData.es6';
import WebformSubmitter from 'redux/actions/submitters/WebformSubmitter.es6';

import componentMapper from 'forms/components/componentMapper.es6';

import FormTemplate from 'forms/components/FormTemplate';
import addInitialState from 'utils/addInitialState.es6';
import { common } from '@material-ui/core/colors';

const MuiFormTemplate = (props) => (
  <FormTemplate {...props} showFormControls={false} />
);

const useStyles = makeStyles((theme) => ({
  surveyForm: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    '& .section .heading h2': {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    '& .section[name^="section_"]': {

      '& > .field': {
        borderTop: 0,
        paddingTop: 0,
      },

      '& .heading': {
        textAlign: 'center',

        '& .MuiTypography-root': {
          display: 'inline-block',
          margin: theme.spacing(0, 'auto'),
          padding: theme.spacing(0.5),
          borderRadius: 2,
          backgroundColor: common.white,
          color: theme.palette.secondary.main,
          fontSize: 12,
          fontWeight: 'bold',
          lineHeight: 1,
          textAlign: 'center',
          '&h1': {
            color: common.white,
          },
          '&h2': {
            color: common.white,
          },
          '&h3': {
            color: common.white,
          },
          '&h4': {
            color: common.white,
          },
          '&h5': {
            color: common.white,
          },
          '&h6': {
            color: common.white,
          },
        },
      },
      '& .description': {
        display: 'block',
        width: '100%',
        textAlign: 'center',
        '& h1': {
          marginTop: theme.spacing(2),
          fontFamily: theme.typography.h1.fontFamily,
          fontSize: '1.5rem',
          color: common.white,
        },
      },
    },
    '& .field': {
      width: '100%',
      margin: theme.spacing(2.5, 0, 0),
      borderTop: theme.hrMed,
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(1),

      '&.markup': {
        marginTop: 0,
        borderTop: 0,
        paddingTop: 0,
      },
      '& .MuiFormLabel-asterisk': {
        display: 'none',
        visibility: 'hidden',
      }
    },
    '& .field.markup': {
      fontSize: '0.9rem',
    },
    // '& .fields > .field:first-child': {
    //   borderTop: 0,
    // },
    '& .options': {
      marginTop: theme.spacing(-1),
    },
    // '& label + .MuiInput-formControl': {
    //   marginTop: 0,
    // },
    '& .section': {
      '&[name="do_you_have_any_blood_relatives_natural_or_biological_father_mot"], &[name="head"], &[name="heart_and_circulation"], &[name="bloodstream"], &[name="internal_organs"], &[name="muscles_and_bones"], &[name="below_the_belt"], &[name="have_you_ever_been_on_the_following_medication_or_treatments_"]': {
        '& > .field.hasDescription': {
          fontSize: '1.1rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        },
        '& .field:not(.hasDescription)': {
          borderTop: theme.hrLight,
        },
        '& fieldset': {
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
          },
        },
        '& .options': {
          width: 'auto',
          marginLeft: theme.spacing(1),
          flexDirection: 'row',
          flexWrap: 'nowrap',
          flexShrink: 0,
        },
      },
    },
  },
}));

const SurveyForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const webform_id = 'survey';
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchWebformMetaData(webform_id));
  }, [i18n, i18n.language]);

  const { metaData, isLoading } = useSelector((state) => state.webform);
  const { draft } = useSelector((state) => state.user);

  return !isEmpty(metaData[webform_id]) && !isLoading[webform_id] ? (
    <>
      <div className={classes.surveyForm}>
        <FormRenderer
          schema={addInitialState(
            metaData[webform_id].schema,
            draft ? draft.initialState : {},
          )}
          FormTemplate={MuiFormTemplate}
          componentMapper={componentMapper}
          onSubmit={WebformSubmitter({
            dispatch,
            history,
            t,
            webform_id: metaData[webform_id].id,
            redirect: '/survey/result/:sid',
            message: t('Survey was successfully submitted.'),
          })}
        />
      </div>
    </>
  ) : (
    <LinearProgress />
  );
};

export default SurveyForm;
