import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import EmailIcon from '@material-ui/icons/Email';
import ShareSVG from 'images/MHC-Results/share.svg';

import common from '@material-ui/core/colors/common';

const useStyles = makeStyles((theme) => ({
  bgWhite: theme.bgWhite,
  sharing: {
    padding: theme.spacing(3, 5),
  },
  sharingInner: {
    maxWidth: 350,
    margin: theme.spacing(0, 'auto'),
  },
  sharingInfo: {
    maxWidth: 300,
    margin: theme.spacing(0, 'auto'),
    '& img': {
      float: 'left',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '& h3': {
      margin: theme.spacing(0, 0, 1),
      fontSize: 20,
      lineHeight: 1,
      textTransform: 'uppercase',
    },
    '& p': {
      margin: 0,
      fontSize: 14,
      lineHeight: 1,
    },
  },
  resultsButtons: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  resultsButton: {
    backgroundColor: theme.palette.primary.dark,
    color: common.white,
  },
}));

const SharingButtons = ({ format }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const emailSubject = t('Try #MensHealthCheck');
  const shareMessage = t(
    'Try #MensHealthCheck, a fast and easy tool that’ll give you a snapshot of your future health!',
  );
  const shareUrl = `${window.location.protocol}//${window.location.host}`;
  const shareText = `${shareMessage} ${shareUrl}`;

  const onFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareUrl,
      )}`,
      'popupWindow',
      'width=600,height=600,scrollbars=yes',
    );
  };

  const onTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`,
      'popupWindow',
      'width=600,height=600,scrollbars=yes',
    );
  };

  const shareButtons = (
    <Grid container className={classes.resultsButtons} spacing={2}>
      <Grid item xs={4}>
        <Button
          className={classes.resultsButton}
          fullWidth
          onClick={onFacebook}
          variant="contained"
          aria-label={t('Share on Facebook')}
        >
          <FacebookIcon />
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          className={classes.resultsButton}
          fullWidth
          onClick={onTwitter}
          variant="contained"
          aria-label={t('Share on Twitter')}
        >
          <TwitterIcon />
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Button
          className={classes.resultsButton}
          fullWidth
          href={`mailto:?subject=${emailSubject}&body=${shareMessage}`}
          variant="contained"
          aria-label={t('Share by Email')}
        >
          <EmailIcon />
        </Button>
      </Grid>
    </Grid>
  );

  const resultsButtons = (
    <Box className={classes.sharingInner}>
      <Box className={classes.sharingInfo}>
        <img src={`${process.env.IMAGE_ROOT}${ShareSVG}`} alt={t('')} />
        <h3>{t('Great Job!')}</h3>
        <p>{t('Now encourage other men to take the survey by sharing')}:</p>
      </Box>
      {shareButtons}
    </Box>
  );

  const defaultButtons = (
    <Box className={classes.sharingInner}>
      <Box className={classes.sharingInfo}>
        <img src={`${process.env.IMAGE_ROOT}${ShareSVG}`} alt={t('')} />
        <h3>{t('Share It!')}</h3>
        <p>{t('Encourage other men to take the survey by sharing')}:</p>
      </Box>
      {shareButtons}
    </Box>
  );

  let buttons = defaultButtons;

  if (format === 'results') {
    buttons = resultsButtons;
  }

  return (
    <>
      <Box className={`${classes.bgWhite} ${classes.sharing}`}>{buttons}</Box>
    </>
  );
};

SharingButtons.propTypes = {
  format: PropTypes.string,
};

SharingButtons.defaultProps = {
  format: '',
};

export default SharingButtons;
