import { drupalApi } from 'utils/RestConnector.es6';
import { setLastMessage } from 'redux/reducers/Message.es6';
import { clearDraft, setLastResult } from 'redux/reducers/User.es6';

/**
 * Runs redux dispatch action to submit Webform.
 *
 * @param {function} dispatch
 * @param {object} history
 * @param {function} t
 * @param {string} webform_id
 * @param {string} redirect
 * @param {string} message
 *
 * @return {Promise}
 *
 * @constructor inherit
 */
export default ({
  dispatch,
  history,
  t,
  webform_id,
  redirect = '',
  message = t('Webform was successfully submitted.'),
}) => (data) => {
  dispatch(
    setLastMessage({
      data: {
        message: t('Submitting...'),
      },
      type: 'info',
    }),
  );
  drupalApi
    .post('/webform_rest/submit', { ...data, webform_id })
    .then((response) => {
      dispatch(
        setLastMessage({
          data: {
            message,
          },
          type: 'success',
        }),
      );
      // @todo: This is hardcoded for now.
      if (webform_id === 'survey') {
        dispatch(setLastResult(response.data.sid));
        dispatch(clearDraft());
      }

      if (redirect) {
        if (redirect === window.location.pathname) {
          setTimeout(function () {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 2);
        } else {
          history.push(redirect.replace(':sid', response.data.sid));
        }
      }
    })
    .catch((error) =>
      // @todo: make this smarter!
      dispatch(setLastMessage({ data: error.response.data, type: 'error' })),
    );
};
