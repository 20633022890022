import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import common from '@material-ui/core/colors/common';

import RiskometerCCPNG from 'images/MHC-Results/riskometer-CC.png';
import RiskometerCVPNG from 'images/MHC-Results/riskometer-CV.png';
import RiskometerDMPNG from 'images/MHC-Results/riskometer-DM.png';
import RiskometerDPPNG from 'images/MHC-Results/riskometer-DP.png';
import RiskometerEDPNG from 'images/MHC-Results/riskometer-ED.png';
import RiskometerLTPNG from 'images/MHC-Results/riskometer-LT.png';
import RiskometerOSPNG from 'images/MHC-Results/riskometer-OS.png';
import RiskometerPRPNG from 'images/MHC-Results/riskometer-PR.png';

const useStyles = makeStyles((theme) => ({
  resultItem: {
    position: 'relative',
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(0, 0, 4),
    borderBottom: theme.hrMed,
  },
  riskometer: {
    float: 'left',
    width: 112,
    height: 112,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `2px solid white`,
    borderRadius: '50%',
  },
  icon: {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '60%',
  },
  riskColor: {
    '&.high': {
      backgroundColor: theme.palette.colorRiskHigh,
    },
    '&.medium': {
      backgroundColor: theme.palette.colorRiskMedium,
      color: common.black,
    },
    '&.low': {
      backgroundColor: theme.palette.colorRiskLow,
    },
    '&.already-diagnosed': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  title: {
    paddingTop: 35,
  },
  riskRactor: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  riskPercentage: {
    marginLeft: theme.spacing(0.5),
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 20,
    fontWeight: 'bold',
  },
  resultText: {
    clear: 'both',
    fontSize: 14,
    margin: 'inherit',
  },
  resultButton: {
    border: `2px solid black`,
    backgroundColor: common.white,
    color: common.black,
  },
}));

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const ResultItem = ({
  already_diagnosed,
  disease_key,
  disease_name,
  result_text,
  risk_category,
  risk_percentage,
  result_links,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const buttons = [];

  result_links.map((link) => {
    return buttons.push(
      <Grid item key={`${disease_key} ${link.text}`} xs={12} sm={6}>
        <Button
          variant="contained"
          className={classNames(
            classes.resultButton,
            risk_category,
            {
              'already-diagnosed': already_diagnosed,
            },
            'tips',
            'external',
          )}
          fullWidth
          onClick={() => {
            window.open(link.href, '_blank');
          }}
        >
          {link.text}
        </Button>
      </Grid>,
    );
  });

  let bgImage;
  let bgSize = '100%';

  switch (disease_key) {
    case 'CC':
      bgImage = RiskometerCCPNG;
      break;
    case 'CV':
      bgImage = RiskometerCVPNG;
      break;
    case 'DM':
      bgImage = RiskometerDMPNG;
      break;
    case 'DP':
      bgImage = RiskometerDPPNG;
      break;
    case 'ED':
      bgImage = RiskometerEDPNG;
      break;
    case 'LT':
      bgImage = RiskometerLTPNG;
      break;
    case 'OS':
      bgImage = RiskometerOSPNG;
      break;
    case 'PR40':
    case 'PR40-49':
    case 'PR50+':
      bgImage = RiskometerPRPNG;
      break;
    default:
      break;
  }

  return (
    <>
      <Box
        className={classNames(classes.resultItem, 'result', {
          [`${disease_key}`]: true,
          'already-diagnosed': already_diagnosed,
          [`${risk_category}`]: true,
        })}
      >
        <Box
          className={classNames(
            classes.riskometer,
            risk_category,
            classes.riskColor,
            {
              'already-diagnosed': already_diagnosed,
            },
          )}
        >
          <Box
            className={classes.icon}
            style={{
              backgroundImage: `url(${process.env.IMAGE_ROOT}${bgImage})`,
              backgroundSize: `${bgSize}`,
            }}
          />
        </Box>
        <Typography variant="h3" className={classes.title}>
          {disease_name}
        </Typography>
        <Box className={classes.riskFactor}>
          {already_diagnosed && <>{t('Already Diagnosed')}</>}
          {!already_diagnosed && (
            <>
              {t('Risk factor')}:
              <Box component="span" className={classes.riskPercentage}>
                {Math.round(risk_percentage)}%
              </Box>
            </>
          )}
        </Box>
        <Box className={classes.resultText}>
          <div dangerouslySetInnerHTML={{ __html: result_text }} />
        </Box>
        <Grid container className="buttons" spacing={2}>
          {buttons}
        </Grid>
      </Box>
    </>
  );
};

ResultItem.propTypes = {
  already_diagnosed: PropTypes.bool,
  disease_key: PropTypes.string,
  disease_name: PropTypes.string,
  result_text: PropTypes.string,
  risk_category: PropTypes.string,
  risk_percentage: PropTypes.number,
  result_links: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      href: PropTypes.string,
      '#weight': PropTypes.number,
    }),
  ),
};

export default ResultItem;
