import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HelperTextPNG from 'images/HelperText.png';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    marginLeft: theme.spacing(1),
  },
}));

const HelperTextIcon = ({ helperText }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const renderHTML = (rawHTML) =>
    React.createElement('span', {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <Tooltip title={renderHTML(helperText)} className={classes.tooltip}>
        <img
          className="MuiSvgIcon-root tooltip"
          src={`${process.env.IMAGE_ROOT}${HelperTextPNG}`}
          alt={t('Help')}
        />
      </Tooltip>
    </>
  );
};

export default HelperTextIcon;
