import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearLastMessage } from 'redux/reducers/Message.es6';

/**
 * Component to clear last Message.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const OnRoute = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearLastMessage());
  }, [history.location.pathname]);
  return <></>;
};

export default OnRoute;
