import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormApi from '@data-driven-forms/react-form-renderer/use-form-api';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';

import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
}));

const FormTemplate = ({ formFields, schema, showFormControls = true }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit, getState } = useFormApi();
  const { submitting } = getState();
  return (
    <Grid item xs={12}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container item spacing={4}>
          {schema.title && (
            <Grid item xs={12}>
              <Typography variant="h1" component="h1" align="center">
                {schema.title}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {formFields}
          </Grid>
          {showFormControls && (
            <Grid item xs={12} sm={4}>
              <FormSpy>
                {() => (
                  <div>
                    <Button
                      type="submit"
                      disabled={submitting}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                    >
                      {schema.submitLabel || t('Submit')}
                    </Button>
                  </div>
                )}
              </FormSpy>
            </Grid>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default FormTemplate;
