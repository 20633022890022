/**
 * @file Error handling functions.
 */

import isEmpty from 'lodash-es/isEmpty';

/**
 * Prepare Drupal errors.
 *
 * @param {Object} drupalErrors
 *
 * @return {Object}
 */
export function ErrorsFormatterFromDrupalToObject(drupalErrors) {
  const errors = {};

  if (drupalErrors) {
    if (drupalErrors.message) {
      errors._error = drupalErrors.message;
    }
  } else {
    errors._error = 'Something went wrong with your request';
  }
  if (drupalErrors.error) {
    return drupalErrors.error;
  }

  return errors;
}

/**
 * Returns TRUE if a response has an error.
 *
 * @param {Object} response
 *
 * @return {boolean}
 */
export function isErrors(response) {
  if (typeof response === 'undefined') {
    return true;
  }
  return !isEmpty(response) && response.status >= 299 && response.status < 500;
}
