import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import 'utils/i18n.es6';
import store from 'redux/store.es6';
import mainRoutes from 'utils/routes';

import { ThemeProvider } from '@material-ui/styles';

import theme from 'styles/theme';
import 'styles/core.scss';

const getRoutes = (routes, isAuthenticated) => {
  return routes.map((prop) => {
    return !prop.private || (prop.private && isAuthenticated) ? (
      <Route
        key={prop.path}
        path={prop.path}
        exact={prop.exact}
        render={(props) => (
          <prop.layout>
            <prop.component {...props} />
          </prop.layout>
        )}
      />
    ) : (
      <Route key={prop.path} path={prop.path} exact={prop.exact}>
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      </Route>
    );
  });
};

const Index = () => {
  const { isAuthenticated } = useSelector((state) => state.user);
  return (
    <Router>
      <Switch>{getRoutes(mainRoutes, isAuthenticated)}</Switch>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Index />
    </ThemeProvider>
  </Provider>,
  document.getElementById('menshealthcheck'),
);
