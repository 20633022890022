/**
 * @file Contains "Layout" component.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import common from '@material-ui/core/colors/common';
import Paper from '@material-ui/core/Paper';

import OnRoute from 'components/OnRoute';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Message from 'components/Message';

import BgSurvey1 from 'images/bg-survey-1.png';
import BgSurvey2 from 'images/bg-survey-2.png';
import BgSurvey3 from 'images/bg-survey-3.png';
import BgSurvey4 from 'images/bg-survey-4.png';
import BgSurvey5 from 'images/bg-survey-5.png';
import BgSurveyResults from 'images/bg-survey-results.png';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.baseline,
    backgroundColor: theme.palette.colorBackground,
    backgroundImage: theme.palette.colorGradient,
    color: common.white,
  },
  layout: theme.layout,
  randomBackground: {
    backgroundSize: '100% auto !important',
  },
  main: theme.main,
  paper: theme.paper,
}));

function MainLayout({ children }) {
  const classes = useStyles();
  const backgrounds = [BgSurvey1, BgSurvey2, BgSurvey3, BgSurvey4, BgSurvey5];
  let background = backgrounds[Math.floor(Math.random() * backgrounds.length)];
  const location = useLocation();
  if (location.pathname.startsWith('/survey/result/')) {
    background = BgSurveyResults;
  }

  return (
    <>
      <OnRoute />
      <div className={classes.root}>
        <Header />
        <main
          className={`${classes.layout} ${classes.randomBackground}`}
          style={{
            background: `transparent url(${process.env.IMAGE_ROOT}${background}) center top no-repeat`,
          }}
        >
          <Message />
          <Paper className={`${classes.main} ${classes.paper}`}>
            {children}
          </Paper>
        </main>
        <Footer />
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: PropTypes.object,
};

MainLayout.defaultProps = {
  children: null,
};

export default MainLayout;
