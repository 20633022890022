import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

/**
 * Generates "NotFoundView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function SurveyResultView() {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {t('404')}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {t('Sorry, we couldn`t find this page.')}
      </Typography>
    </>
  );
}

export default SurveyResultView;
