import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ResultItem from 'components/AssessmentResult/ResultItem';

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const Results = ({ results }) => {
  return (
    <>
      <Box>
        {results.map((result) => (
          <ResultItem key={result.disease_key} {...result} />
        ))}
      </Box>
    </>
  );
};

Results.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object),
};

export default Results;
