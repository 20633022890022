import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import common from '@material-ui/core/colors/common';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import html2pdf from 'html2pdf.js';

const useStyles = makeStyles((theme) => ({
  fullWidth: theme.fullWidth,
  bgWhite: theme.bgWhite,
  // main: theme.main,
  pdfDownload: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
  },
  downloadLink: {
    color: common.white,
    textDecoration: 'none',
  },
}));

const PDFDownload = ({ sid }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const opt = {
    margin: 0.75,
    pagebreak: { mode: 'avoid-all' },
    filename: `mhc-result-${sid}`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2, scrollY: 0 },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  };

  const generatePdf = () => {
    const numSections = 5;
    let doc = html2pdf()
      .set(opt)
      .from(document.getElementById('pdf-wrapper-1'))
      .toPdf();
    for (let i = 2; i <= numSections; i++) {
      doc = doc
        .get('pdf')
        .then((pdf) => {
          pdf.addPage();
        })
        .from(document.getElementById(`pdf-wrapper-${i}`))
        .toContainer()
        .toCanvas()
        .toPdf();
    }
    doc.save();
  };

  return (
    <Box
      className={`${classes.pdfDownload} ${classes.fullWidth} ${classes.bgWhite}`}
    >
      <Button
        variant="contained"
        color="secondary"
        endIcon={<PictureAsPdfIcon />}
        onClick={generatePdf}
      >
        {t('Download results')}
      </Button>
    </Box>
  );
};

PDFDownload.propTypes = {
  sid: PropTypes.string.isRequired,
};

export default PDFDownload;
