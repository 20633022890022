import React from 'react';

import LostPasswordForm from 'forms/LostPasswordForm';

/**
 * Generates "LostPasswordView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function LostPasswordView() {
  return (
    <>
      <LostPasswordForm />
    </>
  );
}

export default LostPasswordView;
