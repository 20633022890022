import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CorePartner from './CorePartner';

const useStyles = makeStyles((theme) => ({
  h2: {
    marginBlockEnd: '1.25rem',
    marginBlockStart: '2.5rem',
    marginTop: '40px !important',
    fontSize: '1.5em',
  },
  p: {
    '& a': {
      color: theme.palette.secondary.main,
      '&:hover': {
        textDecoration: 'none',
      }
    },
  },
}));

const Partners = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <h2 className={classes.h2}>{t('Our Partners')}</h2>
      <p className={classes.p}>
          The Canadian Men’s Health Foundation relies on the generous support of individuals, corporations, and government to deliver services at no cost - including the Men’s Health Check tool. Please consider <a href="https://menshealthfoundation.ca/donate/" className="MuiLink-root" target="_blank">donating today</a> and help support healthier lifestyles for men, families and communities across Canada.
      </p>
      <CorePartner />
    </>
  );
};

export default Partners;
