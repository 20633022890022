import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { clearLastMessage } from 'redux/reducers/Message.es6';
import Modal from 'components/Modal';
import LoginForm from 'forms/LoginForm';
import RegisterForm from 'forms/RegisterForm';

const useStyles = makeStyles((theme) => ({
  container: {
    '& button': {
      minHeight: '76px',
    },
  },
}));

const Markup = (props) => {
  const { name, handleHide } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { label } = useFieldApi(props);
  const [openModal, setOpenModal] = React.useState(false);

  /**
   * @todo: Custom logic for handling Sign In / Sign Up buttons.
   */
  const handleClickOpen = () => {
    dispatch(clearLastMessage());
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const SignInButton = () => {
    return (
      <>
        <Grid item xs={12} sm={4} style={{ padding: 8 }} className={classes.container}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleClickOpen}
          >
            {t('Sign In')}
          </Button>
          <Modal open={openModal} onClose={handleClose}>
            <LoginForm inModal onClose={handleClose} />
          </Modal>
        </Grid>
      </>
    );
  };

  const SignUpButton = () => {
    return (
      <>
        <Grid item xs={12} sm={4} style={{ padding: 8 }} className={classes.container}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleClickOpen}
          >
            {t('Sign Up')}
          </Button>
          <Modal open={openModal} onClose={handleClose}>
            <RegisterForm inModal onClose={handleClose} />
          </Modal>
        </Grid>
      </>
    );
  };

  const ContinueAnonymously = () => {
    return (
      <>
        <Grid item xs={12} sm={4} style={{ padding: 8 }} className={classes.container}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            onClick={handleHide}
          >
            {t('Continue anonymously')}
          </Button>
        </Grid>
      </>
    );
  };

  switch (name) {
    case 'sign_in_option':
    case 'sign_in_option2':
      return <SignInButton />;
    case 'sign_up_option':
    case 'sign_up_option2':
      return <SignUpButton />;
    case 'continue_anonymously':
    case 'continue_anonymously2':
      return <ContinueAnonymously />;
    default:
      return (
        <div
          className="field markup"
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
      );
  }
};

Markup.propTypes = {
  // Actual input props.
  name: PropTypes.string.isRequired,
  handleHide: PropTypes.func,
};

Markup.defaultProps = {
  handleHide: null,
};

/**
 * Prevent re-rendering of modal dialogs on form submissions.
 */
export default React.memo(Markup);
