/**
 * @file Redux action.
 */
import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';
import {
  setResultsListData,
  setResultsListIsLoading,
} from 'redux/reducers/ResultsList.es6';

/**
 * Fetch results list data.
 *
 * @return {function(*): void}
 */
export default () => {
  return (dispatch) => {
    dispatch(setResultsListIsLoading(true));
    drupalApi
      .get(`/youcheck_rest/results`)
      .then((response) => {
        batch(() => {
          dispatch(setResultsListData(response.data));
          dispatch(setResultsListIsLoading(false));
        });
      })
      .catch((error) => logger.error(error));
  };
};
