/**
 * @file Constance redux reducer.
 */
import { createSlice } from '@reduxjs/toolkit';

export const assessmentResultSlice = createSlice({
  name: 'assessmentResult',
  initialState: {
    data: null,
    isLoading: false,
  },
  reducers: {
    setResultData: (state, action) => {
      state.data = action.payload;
    },
    setResultIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setResultData,
  setResultIsLoading,
} = assessmentResultSlice.actions;

export default assessmentResultSlice.reducer;
