import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField as MuiTextField,
  FormHelperText,
} from '@material-ui/core';

import FormFieldGrid from 'forms/components/form-field-grid/form-field-grid';
import { validationError } from 'forms/components/validation-error/validation-error';
import { meta, input } from '@data-driven-forms/common/prop-types-templates';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import HelperTextIcon from 'components/HelperTextIcon';

const TextField = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormHelperTextProps,
    inputProps,
    FormFieldGridProps,
    hiddenLabel,
    ...rest
  } = useFieldApi(props);
  const invalid = validationError(meta, validateOnMount);
  const id = `${input.type}-${input.name}`;
  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <Grid container wrap="nowrap" justify="space-between">
        <MuiTextField
          id={id}
          {...input}
          fullWidth
          error={!!invalid}
          disabled={isDisabled}
          label={hiddenLabel ? null : label}
          placeholder={placeholder}
          required={isRequired}
          inputProps={{
            readOnly: isReadOnly,
            'aria-label': label,
            ...inputProps,
          }}
          {...rest}
        />
        {helperText && <HelperTextIcon helperText={helperText} />}
      </Grid>
      {description && (
        <FormHelperText {...FormHelperTextProps}>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </FormHelperText>
      )}
    </FormFieldGrid>
  );
};

TextField.propTypes = {
  input,
  meta,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.node,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  validateOnMount: PropTypes.bool,
  description: PropTypes.node,
  inputProps: PropTypes.object,
  FormFieldGridProps: PropTypes.object,
};

export default TextField;
