import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Box } from '@material-ui/core';
import componentMapper from 'forms/components/componentMapper.es6';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import FormTemplate from 'forms/components/FormTemplate';

import LostPasswordSubmitter from 'redux/actions/submitters/LostPasswordSubmitter.es6';

const schema = (t) => ({
  title: t('Forgot your password?'),
  submitLabel: t('Send me reset password instructions'),
  fields: [
    {
      id: 'text-field-mail',
      component: 'text-field',
      name: 'mail',
      placeholder: '',
      type: 'mail',
      validate: [{ type: 'required', message: t('E-mail is required.') }],
      label: t('E-mail'),
    },
  ],
});

const LostPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <FormRenderer
        schema={schema(t)}
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
        onSubmit={LostPasswordSubmitter({ dispatch, t })}
      />
      <Grid container>
        <Grid item>
          <Box mb={2}>
            <Link to="/login">{t('Sign in')}</Link>
          </Box>
          <Box>
            <Link to="/register">{t('Sign up')}</Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LostPasswordForm;
