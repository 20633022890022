import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import componentMapper from 'forms/components/componentMapper.es6';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import FormTemplate from 'forms/components/FormTemplate';

import LostPasswordResetSubmitter from 'redux/actions/submitters/LostPasswordResetSubmitter.es6';

const schema = (t, user) => ({
  title: t('Change your password'),
  submitLabel: t('Change password'),
  fields: [
    {
      id: 'text-field-name',
      component: 'text-field',
      name: 'name',
      hideField: true,
      placeholder: '',
      initialValue: user.data.current_user.name,
      isRequired: true,
      validate: [{ type: 'required', message: t('username is required.') }],
      label: t('E-mail'),
    },
    {
      id: 'text-field-temp_pass',
      component: 'text-field',
      name: 'temp_pass',
      label: t('Temporary password'),
      type: 'password',
      isRequired: true,
      validate: [
        { type: 'required', message: t('Temporary password is required.') },
      ],
    },
    {
      id: 'text-field-new_pass',
      component: 'text-field',
      name: 'new_pass',
      label: t('New password'),
      type: 'password',
      isRequired: true,
      validate: [{ type: 'required', message: t('New password is required.') }],
    },
  ],
});

const LostPasswordResetForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state);

  return user.isAuthenticated ? (
    <FormRenderer
      schema={schema(t, user)}
      FormTemplate={FormTemplate}
      componentMapper={componentMapper}
      onSubmit={LostPasswordResetSubmitter({ dispatch, history, t })}
    />
  ) : null;
};

export default LostPasswordResetForm;
