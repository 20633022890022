import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { common } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  bgWhite: theme.bgWhite,
  fullWidth: theme.fullWidth,

  healthInYourAge: {
    padding: theme.spacing(3),
    [theme.breakpoints.up(800)]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    marginTop: '0 !important',
  },
  titleBox: {
    position: 'relative',
    margin: theme.spacing(2, 0),
    borderBottom: theme.hrMed,
    borderBottomColor: 'rgba(0, 0, 0, 0.25)',
  },
  inYourTitle: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(0, 2),
    backgroundColor: common.white,
    textTransform: 'none',
    fontSize: '0.875rem !important',
  },
  text: {
    fontSize: 14,
  },
}));

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const HealthInYourAge = ({ title, body }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={`${classes.healthInYourAge} ${classes.fullWidth} ${classes.bgWhite}`}
    >
      <Typography className={classes.title} variant="h2" gutterBottom>
        {t('Health In Your...')}
      </Typography>
      <Box className={classes.titleBox}>
        <Typography className={classes.inYourTitle} variant="h3" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Box
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Box>
  );
};

HealthInYourAge.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

HealthInYourAge.defaultProps = {
  title: '',
  body: '',
};

export default HealthInYourAge;
