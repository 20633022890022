import React from 'react';

import SurveyForm from 'forms/SurveyForm';

/**
 * Generates "SurveyView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function SurveyView() {
  return (
    <>
      <SurveyForm />
    </>
  );
}

export default SurveyView;
