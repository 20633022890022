/**
 * @file Constance redux reducer.
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: null,
  youcheck_results: [],
  isLoading: false,
  error: null,
};

export const resultsListSlice = createSlice({
  name: 'resultsList',
  initialState,
  reducers: {
    setResultsListData: (state, action) => {
      const { title, youcheck_results, error } = action.payload;
      if (error) {
        state.error = error;
      } else {
        state.title = title;
        state.youcheck_results = youcheck_results;
      }
    },
    setResultsListIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setResultsListData,
  setResultsListIsLoading,
} = resultsListSlice.actions;

export default resultsListSlice.reducer;
