/**
 * Populate initialState wizard to DDF schema.
 *
 * @param {object} schema
 * @param {object} initialState
 *
 * @return {object}
 */
const addInitialState = (schema, initialState) => {
  return {
    fields: schema.fields.map((value) =>
      value.component === 'wizard' ? { ...value, initialState } : value,
    ),
  };
};

export default addInitialState;
