import {
  formatCmToIn,
  formatCmToFtIn,
  formatKgToLb,
} from 'utils/unitConversion.es6';

/**
 * Extends slider fieldProps and adds specific scale formatting.
 *
 * @param {object} props
 *
 * @return {object}
 */
const addScaleFormat = ({ scaleDisplay, ...fieldProps }) => {
  switch (scaleDisplay) {
    case 'cm_in':
      return {
        ...fieldProps,
        ...{
          getAriaValueText: formatCmToIn,
          valueLabelFormat: formatCmToIn,
        },
      };
    case 'kg_lb':
      return {
        ...fieldProps,
        ...{
          getAriaValueText: formatKgToLb,
          valueLabelFormat: formatKgToLb,
        },
      };
    case 'cm_ft':
      return {
        ...fieldProps,
        ...{
          getAriaValueText: formatCmToFtIn,
          valueLabelFormat: formatCmToFtIn,
        },
      };
    default:
      return fieldProps;
  }
};

export default addScaleFormat;
