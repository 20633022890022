/**
 * @file Redux action.
 */
import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import { setMetaData, setMetaDataIsLoading } from 'redux/reducers/Webform.es6';
import logger from 'utils/logger.es6';

/**
 * Fetch fields metadata from Webform REST endpoint.
 *
 * @param {string} webformId
 *
 * @return {function(*): void}
 */
export default (webformId) => {
  return (dispatch) => {
    dispatch(setMetaDataIsLoading({ webformId, value: true }));
    drupalApi
      .get(`/youcheck_rest/ddf/${webformId}`)
      .then((data) => {
        batch(() => {
          dispatch(setMetaData(data.data));
          dispatch(setMetaDataIsLoading({ webformId, value: false }));
        });
      })
      .catch((error) => logger.error(error));
  };
};
