import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Message from 'components/Message';
import { common } from '@material-ui/core/colors';

/**
 * Component for displaying re-usable modal dialogs.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const Modal = ({ title, subtitle, children, open, actions, onClose }) => {
  return (
    <Dialog open={open} aria-labelledby="modal-dialog-title" onClose={onClose}>
      <DialogTitle id="modal-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      {(children || subtitle) && (
        <DialogContent>
          <Message inModal />
          {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
          {children}
        </DialogContent>
      )}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: common.black,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.element,
  children: PropTypes.element.isRequired,
};

Modal.defaultProps = {
  title: '',
  subtitle: '',
  actions: null,
};

export default Modal;
