import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import { setLastMessage } from 'redux/reducers/Message.es6';
import UserLogoutAction from 'redux/actions/UserLogoutAction.es6';

/**
 * Runs Redux dispatch action to create profile.
 *
 * @param {function} dispatch
 * @param {object} history
 * @param {function} t
 *
 * @return {Promise}
 *
 * @constructor inherit
 */
export default ({ dispatch, history, t }) => (data) =>
  drupalApi
    .post('/user/lost-password-reset?_format=json', data)
    .then(() => {
      batch(() => {
        dispatch(UserLogoutAction(history, '/login'));
        dispatch(
          setLastMessage({
            data: {
              message: t(
                'Your password was successfully changed. Please login with new password.',
              ),
            },
            type: 'success',
          }),
        );
      });
    })
    .catch((error) =>
      dispatch(setLastMessage({ data: error.response.data, type: 'error' })),
    );
