import axios from 'axios';
import getLanguage from 'utils/getLanguage.es6';

/*
 * Wrapper for axios AJAX library.
 *
 * Basically, we use axios directly. But before axios can be used,
 * - we create an new axios instance
 * - give than instance an AJAX fetched CSRF token, which it will send as header 'X-CSRF-Token' with every request
 * - tell the instance that it should include credentials (cookie) with every request
 * - tell the instance that it should include the query parameter "?_format=json" with every request
 *
 * Finally, we wrap this activity in a Promise and return it, so that callers can
 * rely on the promise to call their own callback on successful resolution of the promise
 * or an error handler if the promise gets rejected.
 */
let singleton = null;

// @todo: can be added the 'async', it allows us to call 'await' later
const RestConnector = () => {
  if (!singleton) {
    try {
      singleton = axios.create({
        baseURL: `${process.env.API_DOMAIN}`, // every request is relative to this URL
        withCredentials: true, // include auth cookie in every request
        params: { _format: 'json' }, // add these query params to every request
      });
    } catch (error) {
      console.error(error);
    }
  }

  return singleton;
};

RestConnector().interceptors.request.use(async (config) => {
  const tokenURL = `${process.env.API_DOMAIN}/session/token`;
  const response = await axios.get(tokenURL, {
    withCredentials: true, // required to send auth cookie
  });

  // Change language prefix dynamically.
  config.baseURL = `${process.env.API_DOMAIN}/${getLanguage()}`;

  config.headers['X-CSRF-Token'] = response.data; // include this header in every request
  return config;
});

const drupalApi = RestConnector();

export { RestConnector as default, drupalApi };
