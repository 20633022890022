import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash-es/isEmpty';

import LinearProgress from '@material-ui/core/LinearProgress';

import fetchPage from 'redux/actions/fetchPage.es6';
import { useTranslation } from 'react-i18next';

import parse, { attributesToProps, domToReact } from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  page: theme.page,
}));

const DisclaimerPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchPage('disclaimer'));
  }, [i18n, i18n.language]);

  const { title, body, isLoading } = useSelector((state) => state.page);

  const options = {
    replace: (domNode) => {
      if (!domNode.attribs || !domNode.attribs.class) {
        return;
      }
      const classes = domNode.attribs.class.split(' ');

      if (classes.includes('MuiButton-root')) {
        const props = attributesToProps(domNode.attribs);
        const href = props.href;
        delete props.href;
        return (
          <Button
            onClick={() => {
              history.push(href);
            }}
            {...props}
          >
            {domToReact(domNode.children, options)}
          </Button>
        );
      }
    },
  };

  return !isEmpty(title) && !isEmpty(body) && !isLoading ? (
    <>
      <Box className={classes.page}>
{ /*        <Typography variant="h1">{title}</Typography> */}
        {parse(body, options)}
      </Box>
    </>
  ) : (
    <LinearProgress />
  );
};

export default DisclaimerPage;
