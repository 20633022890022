/**
 * @file Redux action.
 */
import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';
import { setUserData, setUserDataIsLoading } from 'redux/reducers/User.es6';

/**
 * Fetch data from User REST endpoint.
 *
 * @return {function(*): void}
 */
export default (userId) => {
  return (dispatch) => {
    dispatch(setUserDataIsLoading(true));
    drupalApi
      .get(`/user/${userId}`)
      .then((response) => {
        batch(() => {
          dispatch(setUserData(response.data));
          dispatch(setUserDataIsLoading(false));
        });
      })
      .catch((error) => {
        logger.error(error);
      });
  };
};
