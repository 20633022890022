import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, Box, Button } from '@material-ui/core';

import componentMapper from 'forms/components/componentMapper.es6';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';

import LoginAccountSubmitter from 'redux/actions/submitters/LoginAccountSubmitter.es6';
import FormTemplate from 'forms/components/FormTemplate';

const schema = (t) => ({
  title: t('Login'),
  submitLabel: t('Login'),
  fields: [
    {
      id: 'text-field-name',
      component: 'text-field',
      name: 'name',
      placeholder: '',
      type: 'text',
      isRequired: true,
      validate: [{ type: 'required', message: t('Username is required.') }],
      label: t('Email'),
    },
    {
      id: 'text-field-pass',
      component: 'text-field',
      name: 'pass',
      label: t('Password'),
      type: 'password',
      isRequired: true,
      validate: [{ type: 'required', message: t('Password is required.') }],
    },
  ],
});

const LoginForm = ({ inModal = false, onClose = null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <>
      <FormRenderer
        schema={schema(t)}
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
        onSubmit={LoginAccountSubmitter({
          dispatch,
          history,
          inModal,
          onClose,
        })}
      />
      <Grid container>
        <Grid item>
          <Box mb={2}>
            <Link variant="body2" to="/lost-password">{t('Forgot your password?')}</Link>
          </Box>
          <Box mb={2}>{t('Don`t have a profile?')}</Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => {
                history.push('/register');
              }}
            >
              {t('Create a confidential profile')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default LoginForm;
