import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Wizard from '@data-driven-forms/common/wizard/wizard';
import WizardContext from '@data-driven-forms/react-form-renderer/wizard-context';

import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import WizardNav from 'forms/components/wizard/WizardNav';
import WizardStepButtons from 'forms/components/wizard/WizardStepButtons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  wizardBody: {
    padding: 24,
    paddingTop: 0,
    margin: 0,
  },
}));

const WizardInternal = ({
  buttonLabels,
  stepsInfo,
  ButtonContainerProps,
  StepperProps,
  WizardBodyProps,
  WizardProps,
}) => {
  const { draft } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const {
    formOptions,
    currentStep,
    handlePrev,
    onKeyDown,
    handleNext,
    activeStepIndex,
    maxStepIndex,
    prevSteps,
  } = useContext(WizardContext);

  useEffect(() => {
    if (draft) {
      // We are using this approach instead of formOptions.initialize()
      // because formOptions.initialize() overrides default values for fields.
      formOptions.batch(() => {
        Object.keys(draft.values).forEach((fieldName) => {
          formOptions.change(fieldName, draft.values[fieldName]);
        });
      });
    }
  }, [draft]);

  /**
   * @todo: probably find how we can get wizardState with API.
   * @type {{activeStep, prevSteps, activeStepIndex, maxStepIndex}}
   */
  const wizardState = {
    activeStep: currentStep.name,
    activeStepIndex,
    prevSteps,
    maxStepIndex,
    registeredFieldsHistory: {
      [currentStep.name]: formOptions.getRegisteredFields(),
    },
  };

  const classes = useStyles();

  const buttonLabelsFinal = {
    next: t('Next'),
    submit: t('Finish Survey'),
    cancel: t('Cancel'),
    back: t('Previous'),
    ...buttonLabels,
  };

  return (
    <Grid container spacing={3} {...WizardProps} onKeyDown={onKeyDown}>
      {stepsInfo && (
        <WizardNav
          StepperProps={StepperProps}
          stepsInfo={stepsInfo}
          activeStepIndex={activeStepIndex}
        />
      )}
      <Grid
        container
        {...WizardBodyProps}
        className={`${classes.wizardBody}
          ${WizardBodyProps.className}
          fields`}
      >
        {currentStep.fields.map((item) =>
          formOptions.renderForm([item], formOptions),
        )}
        <WizardStepButtons
          {...currentStep}
          wizardState={wizardState}
          formOptions={formOptions}
          buttonLabels={buttonLabelsFinal}
          handleNext={handleNext}
          handlePrev={handlePrev}
          disableBack={activeStepIndex === 0}
          ButtonContainerProps={ButtonContainerProps}
        />
      </Grid>
    </Grid>
  );
};

WizardInternal.propTypes = {
  buttonLabels: PropTypes.object,
  stepsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      label: PropTypes.node,
      StepLabelProps: PropTypes.object,
      StepProps: PropTypes.object,
    }),
  ),
  ButtonContainerProps: PropTypes.object,
  StepperProps: PropTypes.object,
  WizardBodyProps: PropTypes.object,
  WizardProps: PropTypes.object,
};

WizardInternal.defaultProps = {
  WizardBodyProps: {},
};

const MuiWizard = (props) => <Wizard Wizard={WizardInternal} {...props} />;

export default MuiWizard;
