import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import Partners from './Partners';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: common.white,
  },
  container: {
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(1, 2),
    textAlign: 'center',
    fontSize: 14,
    color: common.black,
  },
  h2: {
    marginBlockEnd: '1.25rem',
    marginBlockStart: '2.5rem',
  }
}));

const Footer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <Partners />
        </div>
      </div>
    </>
  );
};

export default Footer;
