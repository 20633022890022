/**
 * Setup translations for texts.
 * Options: https://www.i18next.com/overview/configuration-options
 * @todo: When we get a service for getting translations. Point this to it.
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from '../locales/en/translation.json';
import translationFr from '../locales/fr/translation.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: translationEn,
      },
      fr: {
        translation: translationFr,
      },
    },
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    fallbackLng: 'en',
    debug: false,
    load: 'all',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnEmptyString: false,
    react: {
      useSuspense: false,
    },
  });

export const locales = {
  en: 'English',
  fr: 'Français',
};

export default i18n;
