/**
 * @file Redux action.
 */
import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';
import { setPageData, setPageDataIsLoading } from 'redux/reducers/Page.es6';

/**
 * Fetch page from REST endpoint.
 *
 * @return {function(*): void}
 */
export default (pageId) => {
  return (dispatch) => {
    dispatch(setPageDataIsLoading(true));
    drupalApi
      .get(`/${pageId}`)
      .then((response) => {
        batch(() => {
          dispatch(setPageData(response.data));
          dispatch(setPageDataIsLoading(false));
        });
      })
      .catch((error) => logger.error(error));
  };
};
