import HomeView from 'views/HomeView';
import DisclaimerView from 'views/DisclaimerView';
import LoginView from 'views/LoginView';
import RegisterView from 'views/RegisterView';
import LostPasswordView from 'views/LostPasswordView';
import LostPasswordResetView from 'views/LostPasswordResetView';
import SurveyView from 'views/SurveyView';
import SurveyResultView from 'views/SurveyResultView';
import NotFoundView from 'views/NotFoundView';
import ProfileEditView from 'views/ProfileEditView';
import ProfileView from 'views/ProfileView';

import HomeLayout from 'layouts/HomeLayout';
import MainLayout from 'layouts/MainLayout';

const mainRoutes = [
  {
    path: '/',
    exact: true,
    name: 'Homepage',
    component: HomeView,
    layout: HomeLayout,
  },
  {
    path: '/disclaimer',
    exact: true,
    name: 'Introduction',
    component: DisclaimerView,
    layout: MainLayout,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    layout: MainLayout,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
    layout: MainLayout,
  },
  {
    path: '/lost-password',
    name: 'Lost password',
    component: LostPasswordView,
    layout: MainLayout,
  },
  {
    path: '/lost-password-reset',
    name: 'Lost password reset',
    component: LostPasswordResetView,
    layout: MainLayout,
    private: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileView,
    layout: MainLayout,
    private: true,
  },
  {
    path: '/profile-edit',
    name: 'Profile edit',
    component: ProfileEditView,
    layout: MainLayout,
    private: true,
  },
  {
    path: '/survey',
    exact: true,
    name: 'Survey',
    component: SurveyView,
    layout: MainLayout,
  },
  {
    path: '/survey/result/:sid',
    name: 'Survey',
    component: SurveyResultView,
    layout: MainLayout,
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFoundView,
    layout: MainLayout,
  },
];

export default mainRoutes;
