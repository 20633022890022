import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { common } from '@material-ui/core/colors';
import fetchPage from 'redux/actions/fetchPage.es6';

const useStyles = makeStyles((theme) => ({
  bgWhite: theme.bgWhite,
  fullWidth: theme.fullWidth,

  resultCTA: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up(800)]: {
      padding: theme.spacing(4),
    },
    '& a': {
      color: theme.palette.secondary.main,
      '&.MuiButton-root.MuiButton-containedSecondary': {
        textDecoration: 'none',
        fontStyle: 'normal',
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main,
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      }
    },
    '& .MuiBox-root': {
      '& h1': {
        color: 'inherit',
      },
      '& h2': {
        color: 'inherit',
      },
      '& h3': {
        color: 'inherit',
      },
      '& h4': {
        color: 'inherit',
      },
      '& h5': {
        color: 'inherit',
      },
    },
  },
  text: {
    fontSize: 14,
  },
}));

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const ResultCTA = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPage('resultcta'));
  }, [i18n, i18n.language]);

  const { title, body, isLoading } = useSelector((state) => state.page);

  return (
    <Box
      className={`${classes.resultCTA} ${classes.fullWidth} ${classes.bgWhite}`}
    >
      <Box
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </Box>
  );
};

ResultCTA.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

ResultCTA.defaultProps = {
  title: '',
  body: '',
};

export default ResultCTA;
