import React from 'react';

import ProfileEditForm from 'forms/ProfileEditForm';

/**
 * Generates "ProfileEditView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function ProfileEditView() {
  return (
    <>
      <ProfileEditForm />
    </>
  );
}

export default ProfileEditView;
