import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import common from '@material-ui/core/colors/common';

let muiTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#87D5F7',
      main: '#4495D1',
      dark: '#1F3F63',
    },
    secondary: {
      light: '#ff9651',
      main: '#CF4A0C',
      dark: '#b83400',
    },
    error: {
      main: '#ba000d',
    },
    colorBackground: '#63a9dd',
    colorGradient: 'linear-gradient(180deg, #1970b1, #63a9dd)',
    colorRiskHigh: '#A20606',
    colorRiskMedium: '#D8B701',
    colorRiskLow: '#208D00',
    link: {
      color: common.white,
      textDecoration: 'underline',
      fontStyle: 'italic',
      cursor: 'pointer',
    },
    reverseLink: {
      color: '#4495D1',
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    h1: {
      // marginTop: '2rem',
      // marginBottom: '2rem',
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '2rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      color: common.white,
    },
    h2: {
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '1.6rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: common.white,
    },
    h3: {
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '1.4rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: common.white,
    },
    h4: {
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '1.2rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: common.white,
    },
    h5: {
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '1.1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: common.white,
    },
    h6: {
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontSize: '1rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: common.white,
    },
    subtitle1: {
      textTransform: 'none',
      lineHeight: 1.2,
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.3,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiInputLabel: {
      shrink: false,
    },
    MuiInput: {
      disableUnderline: true,
    },
  },
  hrLight: `1px solid rgba(255, 255, 255, 0.15)`,
  hrMed: `2px solid rgba(255, 255, 255, 0.25)`,
  layout: {
    maxWidth: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
  },
});

muiTheme.baseline = {
  boxSizing: 'border-box',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
  '& *, & *::before, & *::after': {
    boxSizing: 'inherit',
  },
  '& strong, & b': {
    fontWeight: 700,
  },
  color: common.white,
  margin: 0,
  fontSize: '1rem',
  fontFamily: '"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif',
  fontWeight: 400,
  lineHeight: 1.43,
  '& a:not(.MuiLink-root)': muiTheme.palette.link,
};

muiTheme.page = {
  body: {
    fontFamily: muiTheme.typography.fontFamily,
  },
  '& h1': {
    ...muiTheme.typography.h1,
    textAlign: 'center',
  },
  '& h2': muiTheme.typography.h2,
  '& h3': muiTheme.typography.h3,
  '& h4': muiTheme.typography.h4,
  '& h5': muiTheme.typography.h5,
  '& h6': muiTheme.typography.h6,
  '& h2, & h3, & h4, & h5, & h6': {
    marginBottom: 0,
    '& + p': {
      marginTop: 0,
    },
  },
  '& a:not(.MuiButton-root):not(.MuiLink-root)': muiTheme.palette.link,
};

muiTheme.hrTop = {
  marginTop: muiTheme.spacing(4),
  borderTop: muiTheme.hrMed,
};

muiTheme.fullWidth = {
  marginLeft: muiTheme.spacing(-1),
  marginRight: muiTheme.spacing(-1),
  paddingLeft: muiTheme.spacing(1),
  paddingRight: muiTheme.spacing(1),
  [muiTheme.breakpoints.up(800)]: {
    marginLeft: muiTheme.spacing(-3),
    marginRight: muiTheme.spacing(-3),
    paddingLeft: muiTheme.spacing(3),
    paddingRight: muiTheme.spacing(3),
  },
};

muiTheme.noSpacing = {
  paddingLeft: 0,
  paddingRight: 0,
};

muiTheme.main = {
  margin: muiTheme.spacing(2, 0),
  padding: muiTheme.spacing(1),
  [muiTheme.breakpoints.up(800)]: {
    marginTop: muiTheme.spacing(4),
    marginBottom: muiTheme.spacing(4),
    padding: muiTheme.spacing(3),
  },
  // '& a:not(.MuiButton-root):not(.MuiLink-root)': muiTheme.palette.link,
};

muiTheme.paper = {
  backgroundColor: 'transparent',
  color: common.white,
  boxShadow: 'none',
};

muiTheme.bgWhite = {
  backgroundColor: common.white,
  color: '#6A6A6A',
  '& h1': {
    color: muiTheme.palette.primary.dark,
  },
  '& h2': {
    color: muiTheme.palette.primary.dark,
  },
  '& h3': {
    color: muiTheme.palette.primary.dark,
  },
  '& h4': {
    color: muiTheme.palette.primary.dark,
  },
  '& h5': {
    color: muiTheme.palette.primary.dark,
  },
  '& h6': {
    color: muiTheme.palette.primary.dark,
  },
};

muiTheme = responsiveFontSizes(muiTheme);

muiTheme.overrides = {
  MuiAlert: {
    root: {
      marginLeft: muiTheme.spacing(2),
      marginRight: muiTheme.spacing(2),
    },
  },
  MuiPaper: {
    root: {
      backgroundColor: muiTheme.palette.colorBackground,
      color: common.white,
      // '& a:not(.MuiButton-root):not(.MuiLink-root)': muiTheme.palette.link,
    },
  },
  MuiDialogContent: {
    root: {
      ...muiTheme.baseline,
      paddingBottom: muiTheme.spacing(2.5),

      '& h1': {
        margin: 0,
      },
    },
  },
  MuiFormControl: {
    root: {
      width: '100%',
      marginTop: muiTheme.spacing(2.5),
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 14,
      lineHeight: 1.2,
    },
  },
  MuiInputBase: {
    input: {
      height: 'auto',
    },
    multiline: {
      padding: 'inherit',
    },
  },
  MuiInput: {
    root: {
      backgroundColor: fade(common.white, 0.8),
      borderRadius: 4,
    },
    input: {
      padding: muiTheme.spacing(2),
    },
  },
  MuiInputLabel: {
    formControl: {
      position: 'static',
      transform: 'none',
    },
    root: {
      color: common.white,
      fontWeight: 'bold',
      fontSize: 14,
      '&$focused': {
        color: common.white,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: common.white,
      marginBottom: 12,
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 'bold',
      '&$focused': {
        color: common.white,
      },
      '& + .MuiInput-formControl': {
        marginTop: 0,
      },
    },
  },
  MuiFormHelperText: {
    root: {
      color: common.white,
      fontSize: '0.8rem',
      'line-height': 1.2,

      '& p:last-child': {
        marginBottom: 0,
      },
    },
  },
  MuiStepConnector: {
    horizontal: {
      backgroundColor: common.white,
      height: 6,
      border: '1px solid white',
      borderLeft: 'none',
      '&:nth-child(2)': {
        borderLeft: '1px solid white',
        'border-top-left-radius': 1000,
        'border-bottom-left-radius': 1000,
      },
      '&:nth-last-child(2)': {
        borderRight: '1px solid white',
        'border-top-right-radius': 1000,
        'border-bottom-right-radius': 1000,
      },
    },
    line: {
      display: 'none',
    },
    active: {
      height: 6,
      backgroundColor: muiTheme.palette.secondary.main,
      border: '1px solid white',
      borderRight: 'none',
      borderLeft: 'none',
    },
    completed: {
      height: 6,
      backgroundColor: muiTheme.palette.secondary.main,
      border: '1px solid white',
      borderRight: 'none',
      borderLeft: 'none',
    },
  },
  MuiStep: {
    completed: {
      display: 'none',
    },
    horizontal: {
      backgroundColor: 'transparent',
      paddingLeft: 0,
      paddingRight: 0,
      '&:first-child': {
        marginRight: -4,
      },
      '&:last-child': {
        marginLeft: -4,
      },
    },
  },
  MuiStepLabel: {
    root: {
      '&.Mui-disabled': {
        display: 'none',
      },
    },
    iconContainer: {
      position: 'relative',
      paddingRight: 0,
      '&::after': {
        content: `' '`,
        display: 'block',
        position: 'absolute',
        top: 5,
        left: 5,
        width: 6,
        height: 6,
        borderRadius: '50%',
        backgroundColor: common.white,
        zIndex: 20,
      },
    },
  },
  MuiStepIcon: {
    root: {
      marginLeft: 0,
      width: 16,
      height: 16,
      '&.MuiStepIcon-active': {
        color: muiTheme.palette.secondary.main,
        border: '1px solid white',
        borderRadius: 1000,
        zIndex: 10,
      },
    },
    text: {
      display: 'none',
    },
  },
  MuiSlider: {
    root: {
      display: 'block',
      width: '90%',
      marginTop: 25,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: 0,
    },
    rail: {
      color: common.white,
      opacity: 1,
      height: 14,
      borderRadius: 1000,
    },
    track: {
      color: muiTheme.palette.secondary.main,
      height: 14,
      borderRadius: 1000,
      border: '1px solid white',
    },
    thumb: {
      width: 24,
      height: 24,
      marginTop: -5,
      marginLeft: -11,
      color: muiTheme.palette.secondary.main,
      border: '1px solid white',
      '&::after': {
        display: 'block',
        width: 12,
        height: 12,
        top: 5,
        left: 5,
        backgroundColor: common.white,
      },
    },
    valueLabel: {
      color: 'transparent',
      left: 'calc(-50% + 7px)',
      top: -20,
      fontSize: 12,
      fontWeight: 'bold',
      'white-space': 'nowrap',
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      padding: muiTheme.spacing(2),
      '&[class*="MuiInput-root"]': {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
  },
  MuiRadio: {
    root: {
      color: common.white,
      paddingRight: muiTheme.spacing(0.5),
      '& .MuiIconButton-label svg:first-of-type': {
        color: common.white,
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: common.white,
    },
  },
  MuiButton: {
    root: {
      fontFamily: [
        '"Lato"',
        '"Helvetica Neue"',
        'Helvetica',
        '"Open Sans"',
        'Arial',
        'sans-serif',
      ].join(','),
      fontWeight: 'bold',
      minWidth: 'none',
    },
  },
};

const theme = muiTheme;

export default theme;
