import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: null,
  body: null,
  isLoading: false,
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    clearPage: () => {
      return initialState;
    },
    setPageData: (state, action) => {
      const { title, body } = action.payload;
      state.title = title[0].value;
      state.body = body[0].processed;
    },
    setPageDataIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export default pageSlice.reducer;

export const { clearPage, setPageData, setPageDataIsLoading } = pageSlice.actions;
