import React from 'react';

import LostPasswordResetForm from 'forms/LostPasswordResetForm';

/**
 * Generates "LostPasswordResetView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function LostPasswordResetView() {
  return (
    <>
      <LostPasswordResetForm />
    </>
  );
}

export default LostPasswordResetView;
