import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import getUsername from 'utils/getUsername.es6';
import { loginSuccess, clearLastResult } from 'redux/reducers/User.es6';
import { setLastMessage, clearLastMessage } from 'redux/reducers/Message.es6';

/**
 * Runs Redux dispatch action to login.
 *
 * @param {function} dispatch
 * @param {function} history
 * @param {boolean} inModal
 * @param {function} onClose
 *
 * @return {Promise}
 *
 * @constructor inherit
 */
export default ({ dispatch, history, inModal = false, onClose = null }) => (
  data,
) =>
  getUsername(data.name).then((username) =>
    drupalApi
      .post('/user/login', {
        name: username,
        pass: data.pass,
      })
      .then((response) => {
        batch(() => {
          // Clear lastResult in case user created a submission as one user,
          // logged out, and then logged in as another user.
          dispatch(clearLastResult());
          dispatch(loginSuccess(response.data));
          if (response.data.temp_pass) {
            dispatch(
              setLastMessage({
                data: { message: response.data.temp_pass },
                type: 'warning',
              }),
            );
            history.push('/lost-password-reset');
          } else {
            dispatch(clearLastMessage());
            if (inModal) {
              onClose();
            } else {
              history.push('/profile');
            }
          }
        });
      })
      .catch((error) =>
        dispatch(
          setLastMessage({
            data: error.response.data,
            type: 'error',
            showInModal: inModal,
          }),
        ),
      ),
  );
