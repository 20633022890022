/**
 * @file Contains "Layout" component.
 */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import common from '@material-ui/core/colors/common';

import OnRoute from 'components/OnRoute';
import Header from 'components/Header';
import Message from 'components/Message';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.baseline,
    backgroundColor: theme.palette.colorBackground,
    backgroundImage: theme.palette.colorGradient,
    color: common.white,
    paddingBottom: theme.spacing(2),
  },
  layout: theme.layout,
  main: theme.main,
}));

function HomeLayout({ children }) {
  const classes = useStyles();

  return (
    <>
      <OnRoute />
      <div className={classes.root}>
        <Header />
        <main className={classes.layout}>
          <Message className={classes.main} />
          {children}
        </main>
      </div>
    </>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.object,
};

HomeLayout.defaultProps = {
  children: null,
};

export default HomeLayout;
