import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  basicStats: {},
  ethnicity: {
    '& .label': {
      display: 'none',
    },
    '& .value': {
      position: 'absolute',
      top: -2,
      right: 8,
      transform: 'translateY(-50%)',
      padding: theme.spacing(0, 1),
      fontSize: 12,
    },
  },
  stat: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    fontFamily: theme.typography.h1.fontFamily,

    '& .label': {
      fontSize: 12,
    },
    '& .value': {
      fontWeight: '900',
      fontSize: 28,
      lineHeight: 1,

      '& .units': {
        fontSize: 12,
      },
    },
  },
}));

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const BasicStats = ({
  className,
  ethnicity,
  age,
  bmi,
  height_in_cm,
  height_in_feet_inches,
  weight_in_kilos,
  weight_in_pounds,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Box className={`${className} ${classes.basicStats}`}>
        <Typography variant="h2" gutterBottom>
          {t('Basic Stats')}
        </Typography>
        <Grid container justify="space-evenly">
          <Grid item className={classes.stat}>
            <Box className="label">{t('Age')}</Box>
            <Box className="value">{age}</Box>
          </Grid>
          <Grid item className={classes.stat}>
            <Box className="label">
              {t('Height')} ({height_in_feet_inches})
            </Box>
            <Box className="value">
              {height_in_cm}
              <Box component="span" className="units">
                {t('cm')}
              </Box>
            </Box>
          </Grid>
          <Grid item className={classes.stat}>
            <Box className="label">
              {t('Weight')} ({weight_in_pounds}lbs)
            </Box>
            <Box className="value">
              {weight_in_kilos}
              <Box component="span" className="units">
                {t('kg')}
              </Box>
            </Box>
          </Grid>
          <Grid item className={classes.stat}>
            <Box className="label">{t('BMI')}</Box>
            <Box className="value">{bmi}</Box>
          </Grid>
        </Grid>
        <Grid container justify="space-evenly">
          <Grid item className={classes.stat}>
            <Box className="label">
              {t('Ethnicity')}
            </Box>
            <Box className="value">
              <Box component="span" className="units">
                {ethnicity}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

BasicStats.propTypes = {
  className: PropTypes.string,
  ethnicity: PropTypes.string,
  age: PropTypes.string,
  bmi: PropTypes.string,
  height_in_cm: PropTypes.string,
  height_in_feet_inches: PropTypes.string,
  weight_in_kilos: PropTypes.string,
  weight_in_pounds: PropTypes.string,
};

BasicStats.defaultProps = {
  className: '',
  ethnicity: '',
  age: '',
  bmi: '',
  height_in_cm: '',
  height_in_feet_inches: '',
  weight_in_kilos: '',
  weight_in_pounds: '',
};

export default BasicStats;
