import { drupalApi } from 'utils/RestConnector.es6';
import { setLastMessage } from 'redux/reducers/Message.es6';

/**
 * Runs Redux dispatch action to login.
 *
 * @param {function} dispatch
 * @param {function} t
 * @param {number} userId
 *
 * @return {Promise}
 *
 * @constructor inherit
 */
export default ({ dispatch, t, userId, history, redirect }) => (data) =>
  drupalApi
    .patch(`/user/${userId}`, {
      mail: [{ value: data.mail }],
      field_accept_electronic_comms: {
        value: data.accept_electronic_comms,
      },
      ...((data.current_pass || data.pass2) && {
        pass: [{ existing: data.current_pass, value: data.pass2 }],
      }),
    })
    .then(() => {
      dispatch(
        setLastMessage({
          data: { message: t('The changes have been saved.') },
          type: 'success',
        }),
      );

      if (redirect) {
        if (redirect === window.location.pathname) {
          setTimeout(function () {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          }, 2);
        } else {
          history.push(redirect);
        }
      }
    })
    .catch((error) =>
      dispatch(setLastMessage({ data: error.response.data, type: 'error' })),
    );
