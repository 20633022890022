import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import LogoPNGen from 'images/BCID_Supported_H_RGB_pos_300.png';
import LogoPNGfr from 'images/CB-AvecLeSoutien-V-RGB-pos_250.png';

const logos = {
  en: LogoPNGen,
  fr: LogoPNGfr,
};

const useStyles = makeStyles((theme) => ({
  logo_en: {
    marginTop: -20,
    width: 200,
    [theme.breakpoints.up(800)]: {
      width: 300,
    },
  },
  logo_fr: {
    marginTop: -20,
    width: 167,
    [theme.breakpoints.up(800)]: {
      width: 250,
    },
  },
  heading: {
    marginBlockEnd: '1.25rem',
    marginBlockStart: '2.5rem',
    marginTop: '40px !important',
    fontSize: '1.5em',
  }
}));

const CorePartner = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const logo = logos[i18n.language] || logos['en'];
  const logoStyle = classes[`logo_${i18n.language}`];
  return (
    <>
      <h2 className={classes.heading}>{t('Core Funding Partner')}</h2>
      <img
        src={`${process.env.IMAGE_ROOT}${logo}`}
        alt={t('Supported by the Province of British Columbia')}
        className={logoStyle}
      />
    </>
  );
};

export default CorePartner;
