import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio as MUIRadio,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import HelperTextIcon from 'components/HelperTextIcon';
import { wrapperProps } from '@data-driven-forms/common/multiple-choice-list';
import { makeStyles } from '@material-ui/core/styles';

import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';
import FormFieldGrid from 'forms/components/form-field-grid/form-field-grid';
import { validationError } from 'forms/components/validation-error/validation-error';

const useStyles = makeStyles(() => ({
  grid: {
    '&:first-child': {
      marginTop: 8,
    },
  },
}));

const RadioOption = ({
  name,
  option,
  isDisabled,
  isReadOnly,
  FormControlLabelProps,
  RadioProps: { inputProps, ...RadioProps },
  ...props
}) => {
  const { input } = useFieldApi({ name, type: 'radio', value: option.value });
  return (
    <FormControlLabel
      key={`${name}-${option.value}`}
      control={
        <MUIRadio
          {...input}
          name={name}
          disabled={isDisabled || isReadOnly}
          onChange={() => input.onChange(option.value)}
          inputProps={{
            readOnly: isReadOnly,
            ...inputProps,
          }}
          {...RadioProps}
        />
      }
      label={option.label}
      {...FormControlLabelProps}
      {...props}
    />
  );
};

RadioOption.propTypes = {
  name: PropTypes.string.isRequired,
  option: PropTypes.shape({
    label: PropTypes.node.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  FormControlLabelProps: PropTypes.object,
  RadioProps: PropTypes.object,
};

const Radio = ({ name, ...props }) => {
  const {
    options,
    isDisabled,
    label,
    isRequired,
    helperText,
    description,
    isReadOnly,
    meta,
    validateOnMount,
    FormFieldGridProps,
    FormControlProps,
    FormLabelProps,
    FormHelperTextProps,
    ...rest
  } = useFieldApi({
    ...props,
    name,
    type: 'radio',
  });
  const classes = useStyles();
  const invalid = validationError(meta, validateOnMount);
  const text =
    invalid ||
    ((meta.touched || validateOnMount) && meta.warning) ||
    description;
  return (
    <FormFieldGrid className={classes.grid} {...FormFieldGridProps}>
      <FormControl
        required={isRequired}
        error={!!invalid}
        component="fieldset"
        {...FormControlProps}
      >
        <Grid container wrap="nowrap" justify="space-between">
          <FormLabel component="legend" {...FormLabelProps}>
            {label}
          </FormLabel>
          {helperText && <HelperTextIcon helperText={helperText} />}
        </Grid>
        {description && (
          <FormHelperText {...FormHelperTextProps}>
            <span
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </FormHelperText>
        )}
        <Grid container direction="column" className="options">
          {options.map((option) => (
            <RadioOption
              key={option.value}
              name={name}
              option={option}
              isDisabled={isDisabled}
              isReadOnly={isReadOnly}
              {...rest}
            />
          ))}
        </Grid>
      </FormControl>
    </FormFieldGrid>
  );
};

Radio.propTypes = {
  ...wrapperProps,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
    }),
  ),
  label: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  description: PropTypes.node,
  FormFieldGridProps: PropTypes.object,
  FormControlProps: PropTypes.object,
  FormControlLabelProps: PropTypes.object,
  RadioProps: PropTypes.object,
  FormLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
};

Radio.defaultProps = {
  options: [],
  FormFieldGridProps: {},
  FormControlProps: {},
  FormControlLabelProps: {},
  RadioProps: {},
  FormLabelProps: {},
  FormHelperTextProps: {},
};

export default Radio;
