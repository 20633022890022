/**
 * Use this logger to log errors.
 */
const logger = {
  log(...args) {
    console.log('********************************');
    console.log(console, args);
    console.log('********************************');
  },
  error(error) {
    if (error.response) {
      // Request made and server responded
      console.error(
        `Error ${error.response.status}:`,
        error.response.data.message,
      );
    } else if (error.request) {
      // The request was made but no response was received
      console.error(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error', error.message);
    }
  },
};

export default logger;
