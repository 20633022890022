/**
 * @file Redux action.
 */
import { batch } from 'react-redux';
import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';
import {
  setResultData,
  setResultIsLoading,
} from 'redux/reducers/AssessmentResult.es6';

/**
 * Fetch Assessment result data.
 *
 * @return {function(*): void}
 */
export default (sid) => {
  return (dispatch) => {
    dispatch(setResultIsLoading(true));
    drupalApi
      .get(`/youcheck_rest/result/${sid}`)
      .then((data) => {
        batch(() => {
          dispatch(setResultData(data.data));
          dispatch(setResultIsLoading(false));
        });
      })
      .catch((error) => logger.error(error));
  };
};
