/**
 * @file Constance redux reducer.
 */
import merge from 'lodash-es/merge';
import { createSlice } from '@reduxjs/toolkit';

const userData = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user'))
  : null;

const draftSurvey = localStorage.getItem('draftSurvey')
  ? JSON.parse(localStorage.getItem('draftSurvey'))
  : null;

const lastResult = localStorage.getItem('lastResult')
  ? JSON.parse(localStorage.getItem('lastResult'))
  : null;

const initialState = {
  data: userData || null,
  isAuthenticated: !!userData,
  draft: draftSurvey || null,
  lastResult: lastResult || null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.data = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem('user', JSON.stringify(state.data));
    },
    logoutSuccess: (state) => {
      state.data = null;
      state.isAuthenticated = false;
      localStorage.removeItem('user');
    },
    setAuthState: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserData: (state, action) => {
      state.data.current_user.edit = action.payload;
    },
    setUserDataIsLoading: (state, action) => {
      state.data.isLoading = action.payload;
    },
    setDraft: (state, action) => {
      state.draft = merge({}, state.draft, action.payload);
      localStorage.setItem('draftSurvey', JSON.stringify(state.draft));
    },
    clearDraft: (state) => {
      state.draft = null;
      localStorage.removeItem('draftSurvey');
    },
    setLastResult: (state, action) => {
      state.lastResult = action.payload;
      localStorage.setItem('lastResult', JSON.stringify(state.lastResult));
    },
    clearLastResult: (state) => {
      state.lastResult = null;
      localStorage.removeItem('lastResult');
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginSuccess,
  logoutSuccess,
  setAuthState,
  setUserData,
  setUserDataIsLoading,
  setDraft,
  clearDraft,
  setLastResult,
  clearLastResult,
} = userSlice.actions;

export default userSlice.reducer;
