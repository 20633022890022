import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import Result from 'components/ResultsList/Result';
import { useHistory } from 'react-router';
import { clearDraft } from 'redux/reducers/User.es6';

import fetchResultsListData from 'redux/actions/fetchResultsListData.es6';

const useStyles = makeStyles((theme) => ({
  container: {
    '& button': {
      minHeight: '76px',
    },
  },
}));

/**
 * Component for displaying survey result page.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const ResultsList = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { draft } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchResultsListData());
  }, [i18n, i18n.language]);

  const { title, youcheck_results, isLoading } = useSelector(
    (state) => state.resultsList,
  );

  const items = [];

  youcheck_results.map((result) => {
    return items.push(<Result key={result.uuid} {...result} />);
  });

  let resultsList = <>{t('No results yet.')}</>;

  if (items.length) {
    resultsList = (
      <>
        <List>{items}</List>
      </>
    );
  }

  let buttons = (
    <>
      <Grid item xs={12} sm={4}>
        <Button
          onClick={() => {
            history.push('/survey');
          }}
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          style={{ minHeight: '76px'}}
        >
          {items.length ? t('Retake Survey') : t('Start Survey')}
        </Button>
      </Grid>
    </>
  );

  if (draft && draft.initialState) {
    buttons = (
      <>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            style={{ minHeight: '76px'}}
            onClick={() => {
              history.push('/survey');
            }}
          >
            {t('Continue Survey')}
          </Button>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            style={{ minHeight: '76px'}}
            onClick={() => {
              dispatch(clearDraft());
              history.push('/survey');
            }}
          >
            {t('Restart Survey')}
          </Button>
        </Grid>
      </>
    );
  }

  return !isLoading ? (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            {t('Your Saved Results')}
          </Typography>

          {resultsList}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={4} className={classes.container}>
            {buttons}
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <LinearProgress />
  );
};

export default ResultsList;
