/**
 * @file Redux action.
 */
import { drupalApi } from 'utils/RestConnector.es6';
import logger from 'utils/logger.es6';
import { isErrors } from 'utils/errors.es6';
import getLogoutToken from 'utils/getLogoutToken.es6.js';
import { logoutSuccess, clearLastResult } from 'redux/reducers/User.es6';
import { setLastMessage } from 'redux/reducers/Message.es6';

/**
 * Returns callback function for Redux Dispatcher for customer logout.
 *
 * @return {Promise<void>}
 */
export default (history = null, redirect = '') => (dispatch) => {
  getLogoutToken().then((token) =>
    drupalApi
      .post(`/user/logout`, null, { params: { token } })
      .then((response) => {
        if (isErrors(response)) {
          dispatch(setLastMessage({ data: response.data, type: 'error' }));
          return;
        }
        dispatch(clearLastResult());
        dispatch(logoutSuccess());
        if (history && redirect) {
          history.push(redirect);
        }
      })
      .catch((error) => {
        dispatch(clearLastResult());
        dispatch(logoutSuccess());
        logger.error(error);
      }),
  );
};
