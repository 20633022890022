import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';
import uniqueId from 'lodash-es/uniqueId';

const useStyles = makeStyles((theme) => ({
  fullWidth: theme.fullWidth,
  noSpacing: theme.noSpacing,
  screeningRow: {
    borderTop: theme.hrLight,
    borderBottom: theme.hrLight,
    fontSize: 14,

    '& > div': {
      padding: theme.spacing(1.75, 3.5),
    },
  },
  screeningHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',

    '& .label': {
      backgroundColor: `${theme.palette.primary.dark}60`,
    },
    '& .check': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  screeningContent: {
    '& .screeningTitle': {
      backgroundColor: `${theme.palette.primary.dark}10`,
    },
    '& .screeningFrequency': {
      backgroundColor: `${theme.palette.primary.dark}40`,
    },
    '&:nth-child(odd)': {
      '& .screeningTitle': {
        backgroundColor: `${theme.palette.primary.dark}20`,
      },
      '& .screeningFrequency': {
        backgroundColor: `${theme.palette.primary.dark}51`,
      },
    },
  },
}));

/**
 * Component for displaying result.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const Screenings = ({ screenings }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Box>
        <Typography variant="h2" gutterBottom>
          {t('Health Screenings and Vaccinations')}
        </Typography>

        <Box className={`${classes.fullWidth} ${classes.noSpacing}`}>
          {screenings.map((screening_category) => (
            <Grid container direction="column" key={screening_category.id}>
              <Grid
                container
                direction="row"
                item
                className={`${classes.screeningRow} ${classes.screeningHeader}`}
              >
                <Grid item xs={6} className="label">
                  {screening_category.label}
                </Grid>
                <Grid item xs={6} className="check">
                  {t('Check')}
                </Grid>
              </Grid>
              {screening_category.screenings.map((screening) => (
                <Grid
                  container
                  direction="row"
                  item
                  key={uniqueId()}
                  className={`${classes.screeningRow} ${classes.screeningContent}`}
                >
                  <Grid item xs={6} className="screeningTitle">
                    {screening.title}
                  </Grid>
                  <Grid item xs={6} className="screeningFrequency">
                    {screening.frequency}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ))}
        </Box>
      </Box>
    </>
  );
};

Screenings.propTypes = {
  screenings: PropTypes.arrayOf(PropTypes.object),
};

Screenings.defaultProps = {
  screenings: [],
};

export default Screenings;
