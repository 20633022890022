import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  Slider as MUISlider,
  FormLabel,
  Grid,
} from '@material-ui/core';
import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';

import FormFieldGrid from 'forms/components/form-field-grid/form-field-grid';
import { validationError } from 'forms/components/validation-error/validation-error';
import HelperTextIcon from 'components/HelperTextIcon';
import addScaleFormat from 'utils/addScaleFormat.es6';

const Slider = (props) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    FormFieldGridProps,
    FormControlProps,
    FormGroupProps,
    FormLabelProps,
    FormHelperTextProps,
    before,
    after,
    InputGridProps,
    BeforeGridProps,
    SliderGridProps,
    AfterGridProps,
    ...rest
  } = useFieldApi(addScaleFormat(props));

  const invalid = validationError(meta, validateOnMount);
  const text =
    invalid ||
    ((meta.touched || validateOnMount) && meta.warning) ||
    description;

  return (
    <FormFieldGrid {...FormFieldGridProps}>
      <FormControl
        fullWidth
        required={isRequired}
        error={!!invalid}
        component="fieldset"
        {...FormControlProps}
      >
        <FormGroup {...FormGroupProps}>
          <Grid container wrap="nowrap" justify="space-between">
            <FormLabel component="legend" {...FormLabelProps}>
              {label}
            </FormLabel>
            {helperText && <HelperTextIcon helperText={helperText} />}
          </Grid>
          <Grid container spacing={2} alignItems="center" {...InputGridProps}>
            {before && (
              <Grid item {...BeforeGridProps}>
                {before}
              </Grid>
            )}
            <Grid item xs {...SliderGridProps}>
              <MUISlider
                {...input}
                value={(input.value ? parseFloat(input.value) : false) || (rest.max + rest.min) / 2 || 50}
                {...rest}
                disabled={isDisabled || isReadOnly}
                onChange={(_e, value) => input.onChange(value)}
              />
            </Grid>
            {after && (
              <Grid item {...AfterGridProps}>
                {after}
              </Grid>
            )}
          </Grid>
          {description && (
            <FormHelperText {...FormHelperTextProps}>
              <span
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </FormHelperText>
          )}
        </FormGroup>
      </FormControl>
    </FormFieldGrid>
  );
};

Slider.propTypes = {
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  description: PropTypes.node,
  validateOnMount: PropTypes.bool,
  FormFieldGridProps: PropTypes.object,
  FormControlProps: PropTypes.object,
  FormGroupProps: PropTypes.object,
  FormLabelProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  before: PropTypes.node,
  after: PropTypes.node,
  InputGridProps: PropTypes.object,
  BeforeGridProps: PropTypes.object,
  SliderGridProps: PropTypes.object,
  AfterGridProps: PropTypes.object,
  scaleDisplay: PropTypes.string,
};

export default Slider;
