import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import LinearProgress from '@material-ui/core/LinearProgress';
import componentMapper from 'forms/components/componentMapper.es6';
import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';

import ProfileEditSubmitter from 'redux/actions/submitters/ProfileEditSubmitter.es6';

import FormTemplate from 'forms/components/FormTemplate';
import fetchUserData from 'redux/actions/fetchUserData.es6';
import isEmpty from 'lodash-es/isEmpty';

const schema = (userEdit, t) => ({
  title: t('Profile edit'),
  submitLabel: t('Save'),
  fields: [
    {
      id: 'text-field-mail',
      component: 'text-field',
      name: 'mail',
      placeholder: '',
      type: 'text',
      initialValue: userEdit.mail[0].value ?? '',
      isRequired: true,
      validate: [{ type: 'required', message: t('E-mail is required.') }],
      label: t('E-mail'),
    },
    {
      id: 'text-field-current_pass',
      component: 'text-field',
      name: 'current_pass',
      label: t('Current password'),
      description: t(
        'Required if you want to change the Email address or Password below.',
      ),
      type: 'password',
      validate: [],
    },
    {
      id: 'text-field-pass',
      component: 'text-field',
      name: 'pass',
      label: t('Password'),
      type: 'password',
      validate: [],
    },
    {
      id: 'text-field-pass2',
      component: 'text-field',
      name: 'pass2',
      label: t('Confirm password'),
      description: t(
        'To change the current user password, enter the new password in both fields.',
      ),
      type: 'password',
      validate: [
        (value, { pass }) =>
          value !== pass
            ? t('The specified passwords do not match.')
            : undefined,
      ],
    },
    {
      id: 'checkbox-accept_electronic_comms',
      component: 'checkbox',
      label: t('I consent to receive electronic communications'),
      name: 'accept_electronic_comms',
      initialValue: userEdit.field_accept_electronic_comms[0].value ?? null,
    },
  ],
});

const ProfileEditForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { current_user, isLoading } = useSelector((state) => state.user.data);
  const userId = current_user.uid;
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchUserData(current_user.uid));
  }, []);

  return !isEmpty(current_user.edit) && !isLoading ? (
    <>
      <FormRenderer
        schema={schema(current_user.edit, t)}
        FormTemplate={FormTemplate}
        componentMapper={componentMapper}
        onSubmit={ProfileEditSubmitter({
          dispatch,
          t,
          userId,
          history,
          redirect: window.location.pathname,
        })}
      />
    </>
  ) : (
    <LinearProgress />
  );
};

export default ProfileEditForm;
