import React from 'react';

import LoginForm from 'forms/LoginForm';

/**
 * Generates "LoginView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function LoginView() {
  return (
    <>
      <LoginForm />
    </>
  );
}

export default LoginView;
