import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, LinearProgress } from '@material-ui/core';
import common from '@material-ui/core/colors/common';

import fetchAssessmentResultData from 'redux/actions/fetchAssessmentResultData.es6';

import BasicStats from 'components/AssessmentResult/BasicStats';
import Results from 'components/AssessmentResult/Results';
import ResultKey from 'components/AssessmentResult/ResultKey';
import HealthInYourAge from 'components/AssessmentResult/HealthInYourAge';
import ResultCTA from 'components/AssessmentResult/ResultCTA';
import Screenings from 'components/AssessmentResult/Screenings';
import HealthTipsSignupForm from 'forms/HealthTipsSignupForm';
import ContactForm from 'forms/ContactForm';
import PDFDownload from 'components/AssessmentResult/PDFDownload';
import SharingButtons from 'components/SharingButtons';

const useStyles = makeStyles((theme) => ({
  bgWhite: theme.bgWhite,
  fullWidth: theme.fullWidth,
  hrTop: theme.hrTop,

  surveyResult: {
    '& h1': {
      marginBottom: '2rem',
      color: common.white,
    },
    '& h2': {
      textAlign: 'center',
      fontSize: 20,
      margin: theme.spacing(3, 0),
      color: common.white,
    },
    '& h3': {
      color: common.white,
    },
    '& h4': {
      color: common.white,
    },
    '& h5': {
      color: common.white,
    },
  },

  resultsSection: {
    position: 'relative',
    margin: theme.spacing(4, 'auto'),
    padding: theme.spacing(2, 0),

    '& h2': {
      position: 'absolute',
      top: -7,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      margin: 0,
      padding: theme.spacing(0, 2),
      textTransform: 'none',
      fontSize: '0.875rem !important',
    },
  },
}));

/**
 * Component for displaying survey result page.
 *
 * @return {React.element}
 *
 * @constructor inherit
 */
const SurveyResult = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sid } = useParams();

  useEffect(() => {
    dispatch(fetchAssessmentResultData(sid));
  }, [sid, i18n, i18n.language]);
  const { data, isLoading } = useSelector((state) => state.assessmentResult);

  return data && !isLoading ? (
    <>
      <Box className={classes.surveyResult}>
        <Box id="pdf-wrapper-1">
          <Typography variant="h1" gutterBottom>
            {t('Results and Recommendations')}
          </Typography>
          <BasicStats
            className={classes.resultsSection}
            {...data.basic_stats}
          />
          <ResultKey className={classes.resultsSection} />
          <Results results={data.results.slice(0, 1)} />
        </Box>
        <Box id="pdf-wrapper-2">
          <Results results={data.results.slice(1, 3)} />
        </Box>
        <Box id="pdf-wrapper-3">
          <Results results={data.results.slice(3, 5)} />
        </Box>
        <Box id="pdf-wrapper-4">
          <Results results={data.results.slice(5, 7)} />
        </Box>
        <Box id="pdf-wrapper-5">
          <Results results={data.results.slice(7)} />
{/*           <HealthInYourAge {...data.health_in_your_age[0]} />
          <Screenings screenings={data.screenings} /> */}
        </Box>
        <ResultCTA />
        <PDFDownload sid={sid} />
        <Box className={`${classes.fullWidth} ${classes.bgWhite}`}>
          <SharingButtons format="results" />
        </Box>
        <ContactForm />
{/*        <Box className={classes.hrTop}>
          <HealthTipsSignupForm />
        </Box> */}
      </Box>
    </>
  ) : (
    <LinearProgress />
  );
};

export default SurveyResult;
