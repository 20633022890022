import React from 'react';

import SurveyResult from 'components/SurveyResult';

/**
 * Generates "SurveyResultView" app view.
 *
 * @return {JSX.Element}
 *
 * @constructor inherit
 */
function SurveyResultView() {
  return (
    <>
      <SurveyResult />
    </>
  );
}

export default SurveyResultView;
